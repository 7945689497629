import React from 'react';
import PropTypes from 'prop-types';
import { FactorCard } from './FactorCard';
import styles from './FactorGroup.module.css';

export const FactorGroup = ({ 
  title, 
  factors, 
  tripTypeColor,
  accentTextColor
}) => {
  return (
    <div className={styles.blockGroup}>
      <div className={styles.titleCard} style={{ backgroundColor: tripTypeColor }}>
        <div className={styles.title} style={{ color: accentTextColor }}>{title}</div>
      </div>
      <div className={styles.factorsContainer}>
        {factors.map(factor => (
          <FactorCard
            key={factor.text}
            icon={factor.icon}
            text={factor.text}
          />
        ))}
      </div>
    </div>
  );
};

FactorGroup.propTypes = {
  title: PropTypes.string.isRequired,
  factors: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
