import React from 'react';
import { TestimonialCard } from './compound-components/TestimonialCard';
import PropTypes from 'prop-types';
import styles from './ShopperBasketCarousel.module.css';
import { useDragScroll } from '../../../../constants/useDragScroll';

export const ShopperBasketCarousel = ({ 
  testimonials, 
  cartImages 
}) => {
  const testimonialGridRef = useDragScroll();
  const cartImagesRef = useDragScroll();

  return (
    <section className={styles.testimonialSection}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.subheading}>Basket</h2>
        <h1 className={styles.heading}>SHOPPER BASKETS FOR THIS TRIP</h1>
      </div>
      
      <div className={styles.containerWrapper}>
        <div ref={testimonialGridRef} className={styles.testimonialGrid}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              {...testimonial}
            />
          ))}
        </div>

        <div ref={cartImagesRef} className={styles.cartImages}>
          {cartImages.map((image, index) => (
            <img
              key={index}
              src={image.imageUrl}
              alt=""
              className={styles.cartImage}
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

ShopperBasketCarousel.propTypes = {
  testimonials: PropTypes.array.isRequired,
  cartImages: PropTypes.array.isRequired,
};
