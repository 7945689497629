import React from 'react';
import styles from './QuoteContent.module.css';
import PropTypes from 'prop-types';
export const QuoteContent = ({ 
  quote, 
  name, 
  age, 
  sex, 
  location,
  cardWidth
}) => {
  return (
    <div 
      className={styles.quoteContent}
      style={{
        padding: cardWidth === "FULL_WIDTH" ? '24px 30px' : '24px 20px',
      }}
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/b9e5bf75d923775410ec1f218afd374c51246bef94b0a0f82c3d0b1d5f2be453"
        alt=""
        className={styles.quoteIcon}
      />
      <div className={styles.quoteText}>{quote}</div>
      <div className={styles.authorInfo}>
        <div className={styles.authorName}>{name}</div>
        <div className={styles.authorDetails}>
          <div>{age}</div>
          <div className={styles.detailDivider} />
          <div>{sex}</div>
          <div className={styles.detailDivider} />
          <div>{location}</div>
        </div>
      </div>
    </div>
  );
}; 

QuoteContent.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  sex: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  cardWidth: PropTypes.string.isRequired
};
