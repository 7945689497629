import React from 'react';
import PropTypes from 'prop-types';
import styles from './FactorCard.module.css';

export const FactorCard = ({ 
  title, 
  points, 
  tripTypeColor,
  accentTextColor
}) => {
  return (
    <div className={styles.factorCard}>
      <div className={styles.cardHeader} style={{ backgroundColor: tripTypeColor }}>
        <div className={styles.cardTitle} style={{ color: accentTextColor }}>{title}</div>
      </div>
      <div className={styles.cardContent}>
        {points.map((point, index) => (
          <div key={`${title}-${index}`} className={styles.cardText}>
            {point.text}
          </div>
        ))}
      </div>
    </div>
  );
};

FactorCard.propTypes = {
  title: PropTypes.string.isRequired,
  points: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
