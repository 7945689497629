import React, { useEffect, useRef } from 'react'
import { InstructionsCard } from '../instruction-card/InstructionsCard';
import styles from './AlcFrameworkHeader.module.css'


export const AlcFrameworkHeader = () => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;
      const scrollTo = (scrollWidth - clientWidth) / 2;
      
      scrollContainer.scrollLeft = scrollTo;
    }
  }, []);

  return (
    <header className={styles.hero}>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbb6f6866485c496386462b841bef8a44"
        alt="alcohol framework hero gradient"
        className={styles.heroImage}
      />
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>alcohol category framework</h1>
        <p className={styles.description}>
          A category-centric map that{" "}
          <span className={styles.highlight}>
            defines what products are in and what's outside of total alcohol
          </span>
          , how those products are organized into categories and segments,
          and the drivers of this classification
        </p>
        <img 
          src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feaa6200ff638440cb49b5fc639597810" 
          alt="Flavor Spectrum" 
          className={styles.flavorSpectrumImage} 
        />
        <div 
          ref={scrollContainerRef}
          className={styles.scrollContainer}
        >
          <div className={styles.categoryFrameworkWrapper}>
            <img 
              src="https://res.cloudinary.com/johnnymohawk/image/upload/v1737944273/alc_framework_1_ihpnku.png" 
              alt="Category Framework Breakdown" 
              className={styles.categoryFrameworkImage} 
            />
          </div>
        </div>
        <div className={styles.instructionsCardWrapper}>
          <InstructionsCard />
        </div>
      </div>
    </header>
  )
}
