import React from 'react';
import { StatCard } from './compound-components/StatCard';
import { BasketStats } from './compound-components/BasketStats';
import { CategoryTable } from './compound-components/CategoryTable';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './BasketComposition.module.css';

export function BasketComposition({ 
  tripTypeColor, 
  tableFooter,
  basketData,
  statsData,
  categoryData,
  nonAlcItemCount,
}) {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.basketComposition}>
      <header className={styles.header}>
        <div className={styles.subtitle}>Basket</div>
        <h1 className={styles.title}>Basket composition</h1>
      </header>
      
      <div className={styles.content}>
        <div className={styles.mainStats}>
          <StatCard 
            stats={basketData}
            tableFooter={tableFooter}
          />
          <BasketStats stats={statsData} />
        </div>

        <div className={styles.categorySection}>
          <div 
            className={styles.nonAlcHeader} 
            style={{ backgroundColor: COLORS[tripTypeColor] }}
          >
            {accentTextColor === "#fff" ? <img 
              src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/5d3a0307a039151c38d71f84579f04c42b8e86cd6fbe2a8e1f2ff031502bfeef" 
              alt="Non-alcoholic items icon"
              className={styles.nonAlcIcon} 
            /> : <img 
              src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F90a1d91b43dc40c2b30fdc2c9b69d304" 
              alt="Non-alcoholic items icon"
              className={styles.nonAlcIcon} 
            />}
            <div className={styles.nonAlcStats}>
              <div 
                className={styles.nonAlcTitle} 
                style={{ color: accentTextColor }}
              >
                Typical Non-Alc Basket Items
              </div>
              <div 
                className={styles.nonAlcAmount} 
                style={{ color: accentTextColor }}
              >
                ~{nonAlcItemCount} Items</div>
            </div>
          </div>

          <CategoryTable categories={categoryData} />
        </div>
      </div>

      {tableFooter && <footer className={styles.footer}>
        *Total and Non-Alc Spend data does not include gas
      </footer>}
    </div>
  );
}

BasketComposition.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  tableFooter: PropTypes.bool,
  basketData: PropTypes.object.isRequired,
  statsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoryData: PropTypes.array,
  nonAlcItemCount: PropTypes.number.isRequired,
};
