import React from 'react';
import { DataTag } from './DataTag';
import PropTypes from 'prop-types';
import styles from './TableRow.module.css';

export function TableRow({ data }) {
  return (
    <div className={styles.row}>
      <div className={styles.labelCell}>
        <div className={styles.title}>{data.title}</div>
        <DataTag
          percentage={data.percentage}
          value={data.tag}
          tagColor={data.tagColor}
        />
      </div>
      {data.cells.map((cell, index) => (
        <div key={index} className={styles.dataCell}>
          <div className={styles.content}>{cell.value}%</div>
        </div>
      ))}
    </div>
  );
}

TableRow.propTypes = {
  data: PropTypes.object.isRequired
};
