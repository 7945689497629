import React from 'react';
import PropTypes from 'prop-types';
import styles from './DescriptionBox.module.css';

export const DescriptionBox = ({ backgroundColor, children, accentTextColor }) => {
  return (
    <div className={styles.description} style={{ backgroundColor }}>
      <div 
        className={styles.descriptionText} 
        style={{ color: accentTextColor }}
      >
        {children}
      </div>
    </div>
  );
}; 

DescriptionBox.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  accentTextColor: PropTypes.string.isRequired,
};
