import React from 'react';
import PropTypes from 'prop-types';
import styles from './CalloutSection.module.css';

export const CalloutSection = ({ 
  imageSrc, 
  callout,
  cardWidth
}) => {
  return (
    <div className={styles.calloutSection} style={{
      maxWidth: cardWidth === "FULL_WIDTH" ? '565px' : '300px'
    }}>
      <img
        loading="lazy"
        src={imageSrc}
        alt={callout}
        className={styles.calloutImage}
      />
      <div className={styles.calloutText}>
        {callout.split('\\n').map((line) => (
          <span key={line}>
            {line}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
}; 

CalloutSection.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  callout: PropTypes.string.isRequired,
  cardWidth: PropTypes.string.isRequired
};
