import React from 'react';
import { DemographicsCard } from './compound-components/DemographicsCard';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './ShopperDemographics.module.css';

export const ShopperDemographics = ({ 
  demographicsData, 
  tripTypeColor 
}) => {
  
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <div className={styles.subtitle}>who & when?</div>
        <h2 className={styles.title}>Shopper demographics</h2>
      </div>
      
      <div className={styles.cardGrid}>
        {demographicsData.map((item) => (
          <DemographicsCard 
            key={item.title} 
            title={item.title} 
            tripTypeColor={COLORS[tripTypeColor]}
            accentTextColor={accentTextColor}
          >
            <img 
              src={item.image} 
              alt={`${item.title} breakdown`} 
              className={styles.demographicIcon} 
            />
          </DemographicsCard>
        ))}
      </div>

      <footer className={styles.footer}>
        <p>*Gender is based on a survey question. All other metrics based on Numerator Head of Household data tied to receipt.</p>
        <p>**Age absolutes should be viewed with discretion (given info tied to Head of Household). Indices are more representative.</p>
      </footer>
    </section>
  );
};

ShopperDemographics.propTypes = {
  demographicsData: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired
};
