import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../constants/CatTripColors';
import styles from './StatCard.module.css';


export const StatCard = ({
    value,
    label,
    color,
    textColor
  }) => {
  return (
    <div className={styles.statContainer}>
      <div 
        className={styles.statCircle} 
        style={{ backgroundColor: COLORS[color] }}
      >
        <div 
          className={styles.statInnerCircle} 
          style={{ borderColor: textColor }}
        >
          <div className={styles.statContent}>
            <div 
              className={styles.statValue} 
              style={{ color: textColor }}
            >
              {value}
            </div>
            <div 
              className={styles.statLabel} 
              style={{ color: textColor }}
            >
              {label}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StatCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};
