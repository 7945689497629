import PropTypes from 'prop-types';
import styles from './ChartCard.module.css';

export function ChartCard({ 
  title, 
  image, 
  footerNote 
}) {
  return (
    <div className={styles.chartCard}>
      <h3 className={styles.chartTitle}>{title}</h3>
      <div className={styles.divider} />
      <div className={styles.chartContainer}>
        <img 
          src={image} 
          alt={title}
          className={styles.chartImage} 
        />
        {footerNote && <p className={styles.footerNote}><span className={styles.underline}>Note</span>: {footerNote}</p>}
      </div>
    </div>
  );
}

ChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  footerNote: PropTypes.string
};
