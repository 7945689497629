import React from 'react';
import PropTypes from 'prop-types';
import styles from './AvgTripDuration.module.css';

export const AvgTripDuration = ({ 
  duration, 
  description, 
  tripTypeColor,
  accentTextColor
}) => {
  return (
    <div 
      className={styles.statsCard} 
      style={{ backgroundColor: tripTypeColor, color: accentTextColor }}
    >
      <div className={styles.titleWrapper}>
        {accentTextColor === "#fff" ? (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/947dfea8bc4fc1654d9074839f7c4896d0578ca427e7f5861dbe2fb12b481c02"
            className={styles.icon}
            alt="stopwatch icon"
          />
        ) : (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F20f1cd6e75804dc580d9d0c777b832b2"
            className={styles.icon}
            alt="stopwatch icon"
          />
        )}
        <div className={styles.title}>Avg Trip Duration</div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.statValue}>{duration} Minutes</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

AvgTripDuration.propTypes = {
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired,
};
