import { StatCircle } from './compound-components/StatCircle';
import { StatCard } from './compound-components/StatCard';
import { ThemeCard } from './compound-components/ThemeCard';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './TripTypeWrapUpSection.module.css';

export const TripTypeWrapUpSection = ({
  tripTypeColor,
  tripType,
  description,
  button1Text,
  button2Text,
  stats,
  statFootnote,
  themes1,
  highlightTexts1,
  themes2,
  highlightTexts2
}) => {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.line} />
            <div className={styles.content}>
              <div className={styles.titleSection}>
                <h1 className={styles.title}>Wrap Up</h1>
                <p className={styles.description}>
                  <span className={styles.highlight}>{tripType} </span>{description}
                </p>
                <div className={styles.buttonGroup}>
                  <button className={styles.button}>{button1Text}</button>
                  <button className={styles.button}>{button2Text}</button>
                </div>
              </div>
            </div>
          </div>
          
          <div className={styles.statsContainer}>
            <div className={styles.circleStats}>
              {stats.map((stat) => (
                <StatCircle 
                  key={stat.statLabel} 
                  tripTypeColor={COLORS[tripTypeColor]} 
                  accentTextColor={accentTextColor} 
                  {...stat} 
                />
              ))}
            </div>
            <div className={styles.metricStats}>
              {stats.map((stat) => (
                <StatCard key={stat.metricLabel} {...stat} statFootnote={statFootnote} />
              ))}
            </div>
          </div>
        </div>
        
        {statFootnote && <p className={styles.footnote}>*Spend data does not include gas</p>}

        <div className={styles.themeCards}>
          <div className={styles.themeCardContainer}>
            <ThemeCard 
              tripTypeColor={COLORS[tripTypeColor]}
              accentTextColor={accentTextColor}
              themeTitle="Store Choice Themes" 
              themes={themes1} 
              highlightTexts={highlightTexts1} 
            />
          </div>
          <div className={styles.themeCardContainer}>
            <ThemeCard 
              tripTypeColor={COLORS[tripTypeColor]}
              accentTextColor={accentTextColor}
              themeTitle="Alcohol Choice themes" 
              themes={themes2} 
              highlightTexts={highlightTexts2} 
            />
          </div>
        </div>
        
        <div className={styles.footer}>
          <div className={styles.sourceInfo}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/8846fd6468a065b5497482bdc2cc1c0983aed5bf87290bbb4157f2f3e98fbd52"
              alt="Data Source"
              className={styles.icon}
            />
            <p className={styles.source}>
              Data Source: Numerator Trip Types Study 2024 & NIQ Trip Types Study 2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

TripTypeWrapUpSection.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  tripType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button1Text: PropTypes.string.isRequired,
  button2Text: PropTypes.string.isRequired,
  stats: PropTypes.array.isRequired,
  statFootnote: PropTypes.bool.isRequired,
  themes1: PropTypes.array.isRequired,
  highlightTexts1: PropTypes.array.isRequired,
  themes2: PropTypes.array.isRequired,
  highlightTexts2: PropTypes.array.isRequired,
};
