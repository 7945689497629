import React from 'react';
import { FactorCard } from './compound-components/FactorCard';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './StoreTopFactors2.module.css';

export const StoreTopFactors2 = ({ 
  sectionName,
  factorSectionTitle,
  tripTypeColor, 
  factorsData 
}) => {
  
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.subheading}>{sectionName}</div>
        <div className={styles.mainHeading}>{factorSectionTitle}</div>
      </div>
      <div className={styles.factorsContainer}>
        {factorsData.map((factor) => (
          <FactorCard
            key={factor.title}
            title={factor.title}
            points={factor.points}
            tripTypeColor={COLORS[tripTypeColor]}
            accentTextColor={accentTextColor}
          />
        ))}
      </div>
    </div>
  );
};

StoreTopFactors2.propTypes = {
  sectionName: PropTypes.string.isRequired,
  factorSectionTitle: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  factorsData: PropTypes.array.isRequired
};
