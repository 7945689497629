import React from 'react';
import { Route, Routes, useLocation, Navigate, matchPath } from "react-router-dom";
import Login from '../components/login/';
import TermsConditions from '../components/login/TermsConditions';
import Help from '../components/common/Help';
import Error from '../components/Error';
import Home from '../components/home/';
import LoginLayout from '../components/common/LoginLayout';
import HomeLayout from '../components/common/HomeLayout';
import DemandLandscapeLayout from '../components/common/DemandLandscapeLayout';
import HueDetailPageLayout from '../components/common/HueDetailPageLayout';
import HueHubPageLayout from '../components/common/HueHubPageLayout';
import OccasionsHub from '../components/occasions/hub';
import OccasionsTemplate from '../components/occasions/template';
import DemandSpaceTemplate from '../components/demand-spaces/template';
import DemandSpacesHub from '../components/demand-spaces/hub';
import PeopleSegmentTemplate from "../components/people-segments/template";
import PeopleSegmentHub from "../components/people-segments/hub";
import CategoryLandingPage from '../components/category-landing/CategoryLandingPage';
import TripTypesLandingPage from '../components/trip-types-landing/TripTypesLandingPage';
import HueHomePage from '../components/hue-home-donut/HueHomePage';
import BuilderRoute from "../components/builder/BuilderRoute";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useIsPreviewing } from '@builder.io/react';
import FigmaImportsPage from '../components/figma-imports';
import { AlcoholFrameworkPage } from '../components/alcohol-framework/AlcoholFrameworkPage';
import TripTypesFramework from '../components/trip-types-framework/TripTypesFramework';


// Utility function to sanitize route parameters
const sanitizeParam = (param) => {
    return param.replace(/[^a-zA-Z0-9_-]/g, ""); // Sanitize to allow only alphanumeric, dashes, and underscores
};

// Whitelisted Routes
const allowedRoutes = [
    '/', '/home', '/terms', '/peoplesegment', '/occasions', '/demandspace', '/help'
];

// Route Validator component
const RouteValidator = ({ path, element, paramNames = [], errorElement }) => {
    const location = useLocation();
    // Check if user is authenticated

    const isLoggedIn = localStorage.getItem('isLoggedin') === 'true'; // Replace with your auth logic
    if (!isLoggedIn) {
        return <Navigate to="/" replace />; // Redirect to login
    }


    // Extract params from the URL
    const params = paramNames.reduce((acc, param) => {
        acc[param] = sanitizeParam(location[param] || "");
        return acc;
    }, {});

    // Check if the route is allowed
    if (!allowedRoutes.includes(path) && !paramNames.length) {
        return <Navigate to="/404" replace />;
    }

    // Return the validated route
    return React.cloneElement(element, { ...params }) || errorElement;
};


const AppRoutes = () => {
    const location = useLocation();
    const isPreviewMode = useIsPreviewing() || location.search.includes('builder.preview=page');
    
    const match =
        matchPath("/categories/beer-hub/:page", location.pathname) ||
        matchPath("/categories/hard-bev-hub/:page", location.pathname) ||
        matchPath("/trip-types/:page", location.pathname);
    const page = match?.params?.page;
    const isBeerHub = match?.pattern?.path?.includes('beer-hub');
    const isHardBevHub = match?.pattern?.path?.includes('hard-bev-hub');
    const isTripTypes = match?.pattern?.path?.includes('trip-types');
    let hubType = null;
    if (isBeerHub) {
        hubType = 'beer';
    } else if (isHardBevHub) {
        hubType = 'hardBev';
    } else if (isTripTypes) {
        hubType = 'tripTypes';
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Routes>
                    <Route element={<HomeLayout />}>
                        <Route path='/' element={<HueHomePage />} />
                        <Route path='/home' element={<HueHomePage />} />
                    </Route>
                    <Route element={<HueHubPageLayout />}>
                        <Route path='/categories' element={<CategoryLandingPage />} />
                        <Route path='/trip-types' element={<TripTypesLandingPage />} />
                        <Route path='/categories/framework' element={<AlcoholFrameworkPage />} />
                        <Route path='/trip-types/framework' element={<TripTypesFramework />} />
                        <Route path='/categories/beer-hub' element={<BuilderRoute path='categories/beer-hub' />} />
                        <Route path='/categories/hard-bev-hub' element={<BuilderRoute path='categories/hard-bev-hub' />} />
                    </Route>
                    <Route element={<HueDetailPageLayout page={page} hubType={hubType} />}>
                        <Route path='/categories/beer-hub/:page' element={<BuilderRoute path='categories/beer-hub' />} />
                        <Route path='/categories/hard-bev-hub/:page' element={<BuilderRoute path='categories/hard-bev-hub' />} />
                        <Route path='/trip-types/:page' element={<BuilderRoute path='trip-types' />} />
                    </Route>

                    <Route element={<LoginLayout />}
                        errorElement={<Error />}>
                        <Route path='/terms' element={<TermsConditions />} />
                    </Route>
                    <Route element={<DemandLandscapeLayout />}
                        errorElement={<Error />}>
                        <Route path="*" element={<Login />} />
                        <Route path='/demand-landscape' element={<Home />} />
                        <Route path="/demand-landscape/home" element={<Home />} />

                        {/* Sanitizing Peoplesegment route */}
                        <Route path="/demand-landscape/peoplesegment" element={<PeopleSegmentHub />} />
                        <Route
                            path="/demand-landscape/peoplesegment/:macroSegment/:microSegment"
                            element={<RouteValidator
                                path="/demand-landscape/peoplesegment/:macroSegment/:microSegment"
                                element={<PeopleSegmentTemplate />}
                                paramNames={['macroSegment', 'microSegment']}
                                errorElement={<Error />} />}
                        />

                        {/* Sanitizing Occasions route */}
                        <Route path='/demand-landscape/occasions' element={<OccasionsHub />} />
                        <Route
                            path='/demand-landscape/occasions/:macro/:micro'
                            element={<RouteValidator
                                path="/demand-landscape/occasions/:macro/:micro"
                                element={<OccasionsTemplate />}
                                paramNames={['macro', 'micro']}
                                errorElement={<Error />} />}
                        />

                        {/* Sanitizing Demandspace route */}
                        <Route path="/demand-landscape/demandspace" element={<DemandSpacesHub />} />
                        <Route
                            path='/demand-landscape/demandspace/:space'
                            element={<RouteValidator
                                path="/demand-landscape/demandspace/:space"
                                element={<DemandSpaceTemplate />}
                                paramNames={['space']}
                                errorElement={<Error />} />}
                        />

                        <Route path='/help' element={<Help />} />
                    </Route>
                </Routes>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Routes>
                    {/* Allow Builder preview routes even when unauthenticated */}
                    {isPreviewMode ? (
                        <>
                            <Route element={<HueHubPageLayout />}>
                                <Route path='/categories' element={<BuilderRoute path='categories' />} />
                                <Route path='/trip-types' element={<BuilderRoute path='trip-types' />} />
                                <Route path='/categories/framework' element={<BuilderRoute path='categories/framework' />} />
                                <Route path='/trip-types/framework' element={<BuilderRoute path='trip-types/framework' />} />
                                <Route path='/categories/beer-hub' element={<BuilderRoute path='categories/beer-hub' />} />
                                <Route path='/categories/hard-bev-hub' element={<BuilderRoute path='categories/hard-bev-hub' />} />
                                <Route path='/figma-imports' element={<FigmaImportsPage />} />
                            </Route>
                            <Route element={<HueDetailPageLayout page={page} hubType={hubType} />}>
                                <Route path='/categories/beer-hub/:page' element={<BuilderRoute path='categories/beer-hub' />} />
                                <Route path='/categories/hard-bev-hub/:page' element={<BuilderRoute path='categories/hard-bev-hub' />} />
                                <Route path='/trip-types/:page' element={<BuilderRoute path='trip-types' />} />
                            </Route>
                        </>
                    ) : (
                        <Route element={<LoginLayout />} errorElement={<Error />}>
                            <Route path='*' element={<Login />} />
                            <Route path='/' element={<Login />} />
                        </Route>
                    )}
                </Routes>
            </UnauthenticatedTemplate>
        </>
    );
};

export default AppRoutes;