import React from 'react';
import PropTypes from 'prop-types';
import styles from './FactorCard.module.css';

export const FactorCard = ({ 
  icon, 
  text 
}) => {
  return (
    <div className={styles.factorCard}>
      {icon && <img
        loading="lazy"
        src={icon}
        alt={text}
        className={styles.factorIcon}
      />}
      <div className={styles.factorText}>{text}</div>
    </div>
  );
};

FactorCard.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string.isRequired
};
