import React from 'react';
import { DonutChart } from './DonutChart';
import PropTypes from 'prop-types';
import styles from './ChannelDistribution.module.css';

export const ChannelDistribution = ({ 
  chartImgUrl, 
  channelData
}) => {
  return (
    <div className={styles.chartCard}>
      <div className={styles.chartTitle}>
        Distribution of trips overall
      </div>
      <div className={styles.divider} />
      <div className={styles.chartContent}>
        <DonutChart 
          chartImgUrl={chartImgUrl} 
          chartImgAltText="Distribution of trips overall chart" 
          channelData={channelData}
        />
      </div>
    </div>
  );
}; 

ChannelDistribution.propTypes = {
  chartImgUrl: PropTypes.string.isRequired,
  channelData: PropTypes.array.isRequired,
};
