export const tripTypes = [
  {
    bgColor: 'GRAB_GO_ORANGE',
    iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/d4f94b0636cdda70c1e173d495f6a339bfb16ad06d79e2e6c85c1c91179177ed',
    iconWidth: '54px',
    iconAspectRatio: '0.9',
    text: 'Alcohol grab & go',
    navigateTo: '/trip-types/alcohol-grab-go'
  },
  {
    bgColor: 'ALC_RELOAD_TEAL',
    iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/64c03a9256e2107401be79547d1df9b8c89b43ed1a07c956f73c4aec02893ffd',
    iconWidth: '55px',
    iconAspectRatio: '0.92',
    text: 'Alcohol reload',
    navigateTo: '/trip-types/alcohol-reload'
  },
  {
    bgColor: 'PITSTOP_BLUE',
    iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/91843937a63e0cc3af0eb80b545907a04f410f61992069a1ad8e2fbc01ab3f49',
    iconWidth: '38px',
    iconAspectRatio: '0.63',
    text: 'pit stop essentials',
    navigateTo: '/trip-types/pit-stop-essentials'
  },
  {
    bgColor: 'TODAY_MEAL_GREEN',
    iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/c2087ff2624febc4d18ced43ced1d3901891179064ddcdd8e7f1a2ad540abeb7',
    iconWidth: '46px',
    iconAspectRatio: '0.75',
    text: 'today\'s meal+',
    navigateTo: '/trip-types/todays-meal-plus'
  },
  {
    bgColor: 'FREQ_FILL_IN_RED',
    iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/54a4173e665595d247b0c6762b28acad22709e078e641ab3be2fe9beaed93e01',
    iconWidth: '49px',
    iconAspectRatio: '0.82',
    text: 'frequent fill-in',
    navigateTo: '/trip-types/frequent-fill-in'
  },
  {
    bgColor: 'STOCKUP_PURPLE',
    iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/16a166056dc2e01c5d060a3268e7854c107499e2287d1aededb7cf65820eb8b8',
    iconWidth: '44px',
    iconAspectRatio: '0.73',
    text: 'household stock-up',
    navigateTo: '/trip-types/household-stock-up'
  }
];
