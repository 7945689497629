import { IntroText } from './compound-components/IntroText';
import { WeekpartTable } from './compound-components/WeekpartTable';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './WeekpartDaypart.module.css';

export function WeekpartDaypart({
  tripTypeColor,
  weekpartText,
  boldTexts,
  headerData,
  dayPartData
}) {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <div className={styles.subtitle}>who & when?</div>
        <div className={styles.title}>weekpart and daypart</div>
      </div>
      <div className={styles.content}>
        <IntroText
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
          weekpartText={weekpartText}
          boldTexts={boldTexts}
        />
        <WeekpartTable
          headerData={headerData}
          dayPartData={dayPartData}
        />
      </div>
    </div>
  );
}

WeekpartDaypart.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  weekpartText: PropTypes.string.isRequired,
  boldTexts: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerData: PropTypes.object.isRequired,
  dayPartData: PropTypes.array.isRequired
};
