import React from 'react';
import PropTypes from 'prop-types';
import KeyTakeaways from './compound-components/KeyTakeaways';
import styles from './SectionHeader.module.css';

export function SectionHeader({ 
  sectionTitle, 
  description, 
  takeawayData, 
  takeawayHighlightTexts 
}) {
  return (
    <div className={styles.container}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>
          <div className={styles.highlightLine} />
          <div className={styles.titleWrapper}>
            <h1 className={styles.mainTitle}>{sectionTitle}</h1>
            <p className={styles.mainDescription}>
              {description}
            </p>
          </div>
        </div>
        <KeyTakeaways takeawayData={takeawayData} takeawayHighlightTexts={takeawayHighlightTexts} />
      </div>
    </div>
  );
}

SectionHeader.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  takeawayData: PropTypes.array.isRequired,
  takeawayHighlightTexts: PropTypes.array.isRequired,
};
