import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TakeawayCard.module.css';

export default function TakeawayCard({ 
  takeaways, 
  takeawayHighlightTexts 
}) {
  const textValues = takeawayHighlightTexts.map(obj => obj.text);
  return (
    <div className={styles.container}>
      <div className={styles.card} />
      {takeaways.map((takeaway, index) => (
        <Fragment key={index}>
          <div className={styles.takeawayText}>
            {takeaway.text.split(new RegExp(`(\\b(?:${textValues.join('|')})\\b)`)).map((part, i) => (
              textValues.includes(part.trim()) ? 
                <span key={i} className={styles.highlight}>{part}</span> : part
            ))}
          </div>
          {index < takeaways.length - 1 && <div className={styles.divider} />}
        </Fragment>
      ))}
    </div>
  );
}

TakeawayCard.propTypes = {
  takeaways: PropTypes.array.isRequired,
  takeawayHighlightTexts: PropTypes.array.isRequired,
};
