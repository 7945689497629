import React from 'react';
import PropTypes from 'prop-types';
import styles from './ChartCard.module.css';

export const ChartCard = ({ 
  chartImgUrl, 
}) => {
  return (
    <div className={styles.chartCard}>
      <div className={styles.chartTitle}>
        Alcohol purchase decision
      </div>
      <div className={styles.divider} data-testid="divider" />
      <div className={styles.chartContent}>
        <img 
          src={chartImgUrl} 
          alt="Alcohol purchase decision chart"
          className={styles.chartImage} 
        />
      </div>
    </div>
  );
}; 

ChartCard.propTypes = {
  chartImgUrl: PropTypes.string.isRequired,
};
