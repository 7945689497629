import React from 'react';
import PropTypes from 'prop-types';
import styles from './ThemeCard.module.css';

export function ThemeCard({ title, tripTypeColor, accentTextColor }) {
  return (
    <div 
      className={styles.themeCard} 
      style={{ backgroundColor: tripTypeColor }} 
      data-testid="theme-card"
    >
      <div className={styles.cardTitle} style={{ color: accentTextColor }}>{title}</div>
    </div>
  );
}

ThemeCard.propTypes = {
  title: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired,
};
