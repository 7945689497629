import React, { useState } from 'react';
import { Button } from './compound-components/Button';
import { Card } from './compound-components/Card';
import { ProfileCard } from './compound-components/ProfileCard';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './ShopperJourney.module.css';

const journeyButtons = [
  { journeyId: 1, text: "Trip Journey 1", variant: "primary" },
  { journeyId: 2, text: "Trip Journey 2", variant: "secondary" },
  { journeyId: 3, text: "Trip Journey 3", variant: "secondary" }
];

export const ShopperJourney = ({ 
  tripTypeColor,
  profile1CardData,
  purposeContent1, 
  highlight1, 
  stepsData1,
  shopperJourney1Image1,
  shopperJourney1Image2,
  profile2CardData,
  purposeContent2, 
  highlight2, 
  stepsData2,
  shopperJourney2Image1,
  shopperJourney2Image2,
  profile3CardData,
  purposeContent3, 
  highlight3, 
  stepsData3,
  shopperJourney3Image1,
  shopperJourney3Image2,
}) => {

  const [selectedjourneyId, setSelectedjourneyId] = useState(1);

  function getSelectedJourneyData(journeyId) {
    const journeyData = {
      1: {
        profileCardData: profile1CardData,
        purposeContent: purposeContent1,
        highlight: highlight1,
        stepsData: stepsData1,
        shopperJourneyImage1: shopperJourney1Image1,
        shopperJourneyImage2: shopperJourney1Image2
      },
      2: {
        profileCardData: profile2CardData,
        purposeContent: purposeContent2,
        highlight: highlight2,
        stepsData: stepsData2,
        shopperJourneyImage1: shopperJourney2Image1,
        shopperJourneyImage2: shopperJourney2Image2
      },
      3: {
        profileCardData: profile3CardData,
        purposeContent: purposeContent3,
        highlight: highlight3,
        stepsData: stepsData3,
        shopperJourneyImage1: shopperJourney3Image1,
        shopperJourneyImage2: shopperJourney3Image2
      }
    };

    return journeyData[journeyId] || journeyData[1];
  }

  const selectedJourneyData = getSelectedJourneyData(selectedjourneyId);
  
  const accentTextColor = getAccentTextColor(tripTypeColor);
  const textValues = selectedJourneyData.highlight.map(obj => obj.text);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.titleWrapper}>
            <div className={styles.line} />
            <h1 className={styles.title}>Shopper Journey</h1>
          </div>
        </header>

        <div className={styles.buttonGroup}>
          {journeyButtons.map((button) => (
            <Button 
              key={button.text}
              text={button.text}
              variant={selectedjourneyId === button.journeyId ? 'primary' : 'secondary'}
              onClick={() => {
                setSelectedjourneyId(button.journeyId);
                console.log("clicked", button.journeyId);
              }}
            />
          ))}
        </div>

        <div className={styles.personaSection}>
          <div className={styles.personaContent}>
            <ProfileCard
              image={selectedJourneyData.profileCardData.image}
              name={selectedJourneyData.profileCardData.name}
              description={selectedJourneyData.profileCardData.description}
              relationshipStatus={selectedJourneyData.profileCardData.relationshipStatus}
              drinkerType={selectedJourneyData.profileCardData.drinkerType}
            />
            <Card 
              title="Trip Purpose" 
              purposeContent={true} 
              tripTypeColor={COLORS[tripTypeColor]}
              accentTextColor={accentTextColor}
            >
              {selectedJourneyData.purposeContent.split('\\n').map((line, index) => (
                <span key={`${line}-${index}`}>
                  {line.split(new RegExp(`(\\b(?:${textValues.join('|')})\\b)`)).map((part, i) => (
                    textValues.includes(part.trim()) ? 
                      <span key={i} className={styles.highlightText}>{part}</span> : part
                  ))}
                  <br />
                </span>
              ))}
            </Card>
          </div>
        </div>

        <div className={styles.statsSection}>
          <div className={styles.statsGrid}>
            {selectedJourneyData.stepsData.map((stat) => (
              <Card 
                key={stat.title} 
                title={stat.title}
                tripTypeColor={COLORS[tripTypeColor]}
                accentTextColor={accentTextColor}
              >
                {stat.content}
              </Card>
            ))}
          </div>
          <div className={styles.images}>
            <img
              src={selectedJourneyData.shopperJourneyImage1}
              alt="Shopping journey visualization 1"
              className={styles.journeyImage}
            />
            <img
              src={selectedJourneyData.shopperJourneyImage2}
              alt="Shopping journey visualization 2"
              className={styles.journeyImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ShopperJourney.propTypes = {
  profile1CardData: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    relationshipStatus: PropTypes.string.isRequired,
    drinkerType: PropTypes.string.isRequired,
  }).isRequired,
  purposeContent1: PropTypes.string.isRequired,
  highlight1: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepsData1: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  shopperJourney1Image1: PropTypes.string.isRequired,
  shopperJourney1Image2: PropTypes.string.isRequired,
  profile2CardData: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    relationshipStatus: PropTypes.string.isRequired,
    drinkerType: PropTypes.string.isRequired,
  }).isRequired,
  purposeContent2: PropTypes.string.isRequired,
  highlight2: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepsData2: PropTypes.array.isRequired,
  shopperJourney2Image1: PropTypes.string.isRequired,
  shopperJourney2Image2: PropTypes.string.isRequired,
  profile3CardData: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    relationshipStatus: PropTypes.string.isRequired,
    drinkerType: PropTypes.string.isRequired,
  }).isRequired,
  purposeContent3: PropTypes.string.isRequired,
  highlight3: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepsData3: PropTypes.array.isRequired,
  shopperJourney3Image1: PropTypes.string.isRequired,
  shopperJourney3Image2: PropTypes.string.isRequired,
};
