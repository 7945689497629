import React from 'react';
import { COLORS } from '../../../../../constants/CatTripColors'
import PropTypes from 'prop-types';
import styles from './BasketStats.module.css';

export function BasketStats({ stats }) {
  return (
    <div className={styles.statsContainer}>
      {stats.map((stat) => (
        <div key={stat.label} className={styles.statBlock}>
          <div className={styles.statContent}>
            <div className={styles.amount}>{stat.label === 'Alcohol Spend' && '$'}{stat.amount}</div>
            <div className={styles.index} style={{ backgroundColor: COLORS[stat.tagColor] }}>{stat.index}</div>
          </div>
          <div className={styles.label}>{stat.label}</div>
        </div>
      ))}
    </div>
  );
}

BasketStats.propTypes = {
  stats: PropTypes.array.isRequired,
};
