import React from 'react';
import { TripTypeCard } from './compound-components/TripTypeCard';
import { tripTypes } from '../../../../constants/TripTypesExplorerData';
import styles from './ExploreTripTypes.module.css';

export const ExploreTripTypes = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.divider} />
          <div className={styles.title}>Explore other trip types</div>
          <div className={styles.cardGrid}>
            {tripTypes.map((trip) => (
              <TripTypeCard 
                key={trip.text} 
                {...trip} 
                navigateTo={trip.navigateTo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
