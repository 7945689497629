import React from 'react';
import { COLORS } from '../../../../../constants/CatTripColors';
import PropTypes from 'prop-types';
import styles from './StatCard.module.css';

export const StatCard = ({ 
  value, 
  tag, 
  tagColor, 
  metricLabel,
  statFootnote
}) => {
  return (
    <div className={styles.statWrapper}>
      <div className={styles.stats}>
        <div className={styles.value}>{metricLabel === 'Average $$ Spend' ? <span>$</span> : null}{value}{metricLabel === 'Alc Share of Spend' ? <span>%</span> : null}{statFootnote && <span>*</span>}</div>
        <div 
          className={styles.tag} 
          style={{backgroundColor: COLORS[tagColor]}}
        >
          {tag}
        </div>
      </div>
      <div className={styles.label}>{metricLabel}</div>
    </div>
  );
};

StatCard.propTypes = {
  value: PropTypes.number.isRequired,
  tag: PropTypes.number.isRequired,
  tagColor: PropTypes.string.isRequired,
  metricLabel: PropTypes.string.isRequired,
  statFootnote: PropTypes.bool.isRequired,
};