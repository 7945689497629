import React from 'react';
import { LegendItem } from './LegendItem';
import PropTypes from 'prop-types';
import styles from './DonutChart.module.css';

export const DonutChart = ({ 
  legendData, 
  chartImgUrl, 
  chartImgAltText 
}) => {
  return (
    <div className={styles.donutChart}>
      <img
        loading="lazy"
        src={chartImgUrl}
        className={styles.chartImage}
        alt={chartImgAltText}
      />
      <div className={styles.legend}>
        {legendData.map((item) => (
          <LegendItem key={item.label} {...item} />
        ))}
      </div>
    </div>
  );
};

DonutChart.propTypes = {
  legendData: PropTypes.array.isRequired,
  chartImgUrl: PropTypes.string.isRequired,
  chartImgAltText: PropTypes.string.isRequired,
};
