import React from 'react';
import styles from './TableRow.module.css';
import PropTypes from 'prop-types';
export const TableRow = ({ 
  item, 
  percentage, 
  index, 
  tagColor 
}) => {
  return (
    <>
      <div className={styles.tableItem}>
        <div className={styles.text}>{item}</div>
        <div className={styles.stat}>
          <div className={styles.percentage}>{percentage}%</div>
          <div 
            className={styles.tag} 
            style={{backgroundColor: tagColor}}
          >
            {index}
          </div>
        </div>
      </div>
      <div className={styles.tableLine} />
    </>
  );
};

TableRow.propTypes = {
  item: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  tagColor: PropTypes.string.isRequired,
};