import React from 'react';
import { ChartCard } from './compound-components/ChartCard';
import { DescriptionBox } from './compound-components/DescriptionBox';
import { AvgTripDuration } from './compound-components/AvgTripDuration';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './TripDuration.module.css';

export const TripDuration = ({ 
  tripTypeColor,
  duration,
  description,
  chartImgUrl, 
  tripDescription,
}) => {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subtitle}>In-store experience</div>
        <div className={styles.title}>Trip Duration & Alcohol planning</div>
      </div>
      <AvgTripDuration 
        tripTypeColor={COLORS[tripTypeColor]}
        accentTextColor={accentTextColor}
        duration={duration} 
        description={description} 
      />
      <div className={styles.content}>
        <DescriptionBox 
          style={chartImgUrl ? 
            { backgroundColor: COLORS[tripTypeColor], color: accentTextColor } : 
            { backgroundColor: '#1D1D1D', width: '100%', height: '400px', color: '#fff' }
          }
          titleWidth={chartImgUrl ? '350px' : '100%'}
        >
          {tripDescription}
        </DescriptionBox>
        {chartImgUrl && <ChartCard 
          chartImgUrl={chartImgUrl} 
        />}
      </div>
    </div>
  );
};

TripDuration.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tripDescription: PropTypes.string.isRequired,
  chartImgUrl: PropTypes.string,
};
