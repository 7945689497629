import React, { Fragment } from 'react';
import { NoItemCard } from './NoItemCard';
import { COLORS } from '../../../../../constants/CatTripColors'
import PropTypes from 'prop-types';
import styles from './CategoryTable.module.css';

export function CategoryTable({ categories }) {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.iconTableWrap}>
        <div className={styles.iconTitleWrap}>
          <img src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa7c1b6ef5412411eadaec0b16263afe2" alt="Non-alcoholic items icon" className={styles.nonAlcIcon} />
          <div className={styles.basketTitle}>Non-Alc Categories</div>
        </div>
        { categories.length ? 
          <div className={styles.table}>
            <div className={styles.basketTableTitle}>
              % Frequency in Basket
            </div>
            <div className={styles.tableHeader}>
              <div className={styles.columnName}>Items</div>
              <div className={styles.columnStats}>
                <span>%</span>
                <span>Index</span>
              </div>
            </div>
            {categories.map((category) => (
              <Fragment key={category.name}>
                <div className={styles.divider} />
                <div className={styles.tableRow}>
                  <div className={styles.categoryName}>{category.name}</div>
                  <div className={styles.categoryStats}>
                    <div className={styles.percentage}>{category.percentage}%</div>
                    <div className={styles.index} style={{ backgroundColor: COLORS[category.tagColor] }}>
                      {category.index}
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div> 
        : <NoItemCard /> }
      </div>
    </div>
  );
}

CategoryTable.propTypes = {
  categories: PropTypes.array,
};
