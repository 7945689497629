import React from 'react';
import PropTypes from 'prop-types';
import { PersonaCard } from './PersonaCard';
import styles from './PersonaSection.module.css';

export const PersonaSection = ({ personaData }) => {
  return (
    <div className={styles.personaSection}>
      <div className={styles.personaHeader}>
        <div>Demand Landscape People Segments</div>
        <div className={styles.divider} />
      </div>
      
      <div className={styles.personaGrid}>
        <div className={styles.outgoingFamiliar}>
          <PersonaCard 
            personas={personaData.topLeft} 
            peopleSegmentColor="#94E7C0" 
          />
        </div>
        <div className={styles.outgoingExploration}>
          <PersonaCard 
            personas={personaData.topRight} 
            peopleSegmentColor="#85BCFB" 
          />
        </div>
        <div className={styles.reservedFamiliar}>
          <PersonaCard 
            personas={personaData.bottomLeft} 
            peopleSegmentColor="#ADA1F3" 
          />
        </div>
        <div className={styles.reservedExploration}>
          <PersonaCard 
            personas={personaData.bottomRight} 
            peopleSegmentColor="#F3862F" 
          />
        </div>
      </div>
    </div>
  );
};

PersonaSection.propTypes = {
  personaData: PropTypes.shape({
    topLeft: PropTypes.array.isRequired,
    topRight: PropTypes.array.isRequired,
    bottomLeft: PropTypes.array.isRequired,
    bottomRight: PropTypes.array.isRequired,
  }).isRequired,
}; 
