import React from 'react';
import PropTypes from 'prop-types';
import TakeawayCard from './TakeawayCard';
import styles from './KeyTakeaways.module.css';

export default function KeyTakeaways({ 
  takeawayData, 
  takeawayHighlightTexts 
}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/36bf51a7df881f3b3423d633672ac61892e5df0ae41f9c3df849a5eb3302bf0c"
            className={styles.icon}
            alt="Key takeaways icon"
          />
          <div className={styles.title}>key takeaways</div>
        </div>
      </div>
      <TakeawayCard 
        takeaways={takeawayData} 
        takeawayHighlightTexts={takeawayHighlightTexts} 
      />
    </div>
  );
}

KeyTakeaways.propTypes = {
  takeawayData: PropTypes.array.isRequired,
  takeawayHighlightTexts: PropTypes.array.isRequired,
};
