import React from 'react';
import ShoppingStats from './compound-components/ShoppingStats';
import { PersonaSection } from './compound-components/PersonaSection';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './ShoppersVsConsumers.module.css';

export const ShoppersVsConsumers = ({ 
  tripTypeColor, 
  chartImgUrl,
  personaData, 
  shopperVsConsumerCopy, 
  shopperVsConsumerBold 
}) => {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subtitle}>who & when?</div>
        <div className={styles.title}>Shoppers vs. consumers</div>
      </div>
      <div className={styles.content}>
        <ShoppingStats 
          chartImgUrl={chartImgUrl}
          shopperVsConsumerCopy={shopperVsConsumerCopy}
          shopperVsConsumerBold={shopperVsConsumerBold}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
        <PersonaSection personaData={personaData} />
      </div>
    </div>
  );
};

ShoppersVsConsumers.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  chartImgUrl: PropTypes.string.isRequired,
  personaData: PropTypes.object.isRequired,
  shopperVsConsumerCopy: PropTypes.string.isRequired,
  shopperVsConsumerBold: PropTypes.array.isRequired
};
