import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ progress }) => {
  return (
    <div className={styles.track}>
      <div className={styles.background} />
      <div className={styles.bar}>
        <div 
          className={styles.progress} 
          style={{ width: `${progress}px` }} 
        />
        <div className={styles.remaining} />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
