import { Builder } from '@builder.io/react';
import { AboutSection } from '../alcohol-subcategory/components/about/AboutSection';
import { AssociationCardSection } from '../alcohol-subcategory/components/associations/AssociationCardSection';
import { FlavorExpectations } from '../alcohol-subcategory/components/flavor-expectations/FlavorExpectations';
import { SubcategoryHeader } from '../alcohol-subcategory/components/header/SubcategoryHeader';
import { PricingTable } from '../alcohol-subcategory/components/price-tier/PricingTable';
import { SizingSection } from '../alcohol-subcategory/components/sizing/SizingSection';
import { SubcatQuotes } from '../alcohol-subcategory/components/testimonials/SubcatQuotes';
import { WrapUpSection } from '../alcohol-subcategory/components/wrap-up/WrapUpSection';
import { CategoryStats } from '../category-hub/components/category-size-stats/CategoryStats';
import { TopBrandTable } from '../category-hub/components/category-top-brands-tables/TopBrandTable';
import { SegmentExplorer } from '../category-hub/components/dig-deeper/SegmentExplorer';
import { CategoryHubHeader } from '../category-hub/components/header/CategoryHubHeader';
import { CategoryMethod } from '../category-hub/components/methodology/CategoryMethod';
import { SegmentDisplay } from '../category-hub/components/segment-legend/SegmentDisplay';
import { TripTypesHeader } from '../trip-types-template/components/trip-types-header/TripTypesHeader';
import { SpendAnalytics } from '../trip-types-template/components/spend-analytics/SpendAnalytics';
import { ShoppingAnalytics } from '../trip-types-template/components/shopping-analytics/ShoppingAnalytics';
import { SectionHeader } from '../trip-types-template/components/section-header/SectionHeader';
import { CommonThemes } from '../trip-types-template/components/common-themes/CommonThemes';
import { TestimonialCardSection } from '../trip-types-template/components/testimonial-card-section/TestimonialCardSection';
import { ChannelSelection } from '../trip-types-template/components/channel-selection/ChannelSelection';
import { StoreTopFactors } from '../trip-types-template/components/store-top-factors/StoreTopFactors';
import { StoreTopFactors2 } from '../trip-types-template/components/store-top-factors2/StoreTopFactors2';
import { BasketComposition } from '../trip-types-template/components/basket-composition/BasketComposition';
import { BasketStats } from '../trip-types-template/components/basket-stats/BasketStats';
import { BasketCategoryStats } from '../trip-types-template/components/basket-category-stats/BasketCategoryStats';
import { ShopperBasketCarousel } from '../trip-types-template/components/shopper-basket-carousel/ShopperBasketCarousel';
import { TripDuration } from '../trip-types-template/components/trip-duration/TripDuration';
import { ShopperDemographics } from '../trip-types-template/components/shopper-demo/ShopperDemographics';
import { ShoppersVsConsumers } from '../trip-types-template/components/shoppers-vs-consumers/ShoppersVsConsumers';
import { WeekpartDaypart } from '../trip-types-template/components/weekpart-daypart/WeekpartDaypart';
import { TimeToConsumption } from '../trip-types-template/components/time-to-consumption/TimeToConsumption';
import { ShopperJourney } from '../trip-types-template/components/shopper-journey/ShopperJourney';
import { TripTypeWrapUpSection } from '../trip-types-template/components/wrap-up-section/TripTypeWrapUpSection';
import { ExploreTripTypes } from '../trip-types-template/components/explore-trip-types/ExploreTripTypes';
import { DownloadSection } from '../trip-types-template/components/download-section/DownloadSection';

Builder.registerComponent(AboutSection, {
  name: 'AboutSection',
  inputs: [
    {
      name: 'aboutTitle',
      type: 'string',
      helperText: 'Enter the title for the About section'
    },
    {
      name: 'aboutTitleColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the title text'
    },
    {
      name: 'aboutText',
      type: 'longText',
      helperText: 'Enter the main text for the About section'
    },
    {
      name: 'guidelines',
      type: 'longText',
      helperText: 'Enter the guidelines text'
    },
    {
      name: 'subcatCansImg',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the subcategory cans'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the subcategory cans image'
        }
      ],
      helperText: 'Define the image and alt text for subcategory cans'
    }
  ],
});

Builder.registerComponent(AssociationCardSection, {
  name: 'AssociationCardSection',
  inputs: [
    {
      name: 'cardData',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'text',
          helperText: 'Use \\n for line breaks in card text'
        }
      ],
      helperText: 'Data for each association card entry with custom line breaks'
    },
    {
      name: 'sectionName',
      type: 'string',
      helperText: 'Enter the name of the section'
    },
    {
      name: 'sectionTitle',
      type: 'string',
      helperText: 'Enter the title of the section'
    },
    {
      name: 'subcatAccentColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Key for the accent color of the subcategory'
    },
    {
      name: 'onAccentTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Key for the text color on the accent'
    },
    {
      name: 'sectionNameColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Key for the color of the section name text'
    }
  ]
});

Builder.registerComponent(FlavorExpectations, {
  name: 'FlavorExpectations',
  inputs: [
    {
      name: 'flavorProfile',
      type: 'string',
      helperText: 'Enter the flavor profile'
    },
    {
      name: 'descriptionStart',
      type: 'string',
      helperText: 'Enter the beginning part of the description'
    },
    {
      name: 'descriptionHighlight',
      type: 'string',
      helperText: 'Enter the text to be highlighted in the description'
    },
    {
      name: 'descriptionEnd',
      type: 'string',
      helperText: 'Enter the concluding part of the description'
    },
    {
      name: 'highlightColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the highlighted text'
    },
    {
      name: 'flavorSliderImg',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the flavor slider'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the flavor slider image'
        }
      ],
      helperText: 'Upload an image for the flavor slider'
    }
  ],
});

Builder.registerComponent(SubcategoryHeader, {
  name: 'SubcategoryHeader',
  inputs: [
    {
      name: 'heroImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the hero image for the subcategory'
    },
    {
      name: 'title',
      type: 'string',
      helperText: 'Enter the title of the subcategory'
    },
    {
      name: 'stat1',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          defaultValue: 'X%',
          helperText: 'Enter the statistic value for the first header stat'
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Share of <CAT>',
          helperText: 'Enter the label for the first header stat'
        }
      ],
      helperText: 'Enter the first statistic with value and label'
    },
    {
      name: 'stat2',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          defaultValue: '$X.XB',
          helperText: 'Enter the statistic value for the second header stat'
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Total Size',
          helperText: 'Enter the label for the second header stat'
        }
      ],
      helperText: 'Enter the second statistic with value and label'
    },
    {
      name: 'statColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Select a color category for the stats background'
    },
    {
      name: 'statTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Enter the color for the stat text'
    },
    {
      name: 'fontSizeOverride',
      type: 'string',
      helperText: 'Enter the font size override for the title (in px, exp: 94px)'
    }
  ]
});

Builder.registerComponent(PricingTable, {
  name: 'PricingTable',
  inputs: [
    {
      name: 'pricingTableImage',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for pricing table'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for pricing table image'
        }
      ],
      helperText: 'Upload an image for the pricing table'
    }
  ],
});

Builder.registerComponent(SizingSection, {
  name: 'SizingSection',
  inputs: [
    {
      name: 'sectionName',
      type: 'string',
      helperText: 'Enter the name for the section'
    },
    {
      name: 'sectionTitle',
      type: 'string',
      helperText: 'Enter the title for the section'
    },
    {
      name: 'chartTitle',
      type: 'string',
      helperText: 'Enter the title for the chart'
    },
    {
      name: 'subcatAccentColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the subcategory accent'
    },
    {
      name: 'onAccentTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Enter the color key for the text on the accent'
    },
    {
      name: 'sectionNameColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the section name text'
    },
    {
      name: 'brandShareDataColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color for the brand share data in the top 5 brands table'
    },
    {
      name: 'chartImage',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the chart'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the chart image'
        }
      ],
      helperText: 'Upload an image for the chart'
    },
    {
      name: 'growthMetrics',
      type: 'list',
      subFields: [
        {
          name: 'value',
          type: 'string',
          helperText: 'Enter the growth metric value'
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter the label for the growth metric'
        }
      ],
      helperText: 'Enter growth metrics with values and labels'
    },
    {
      name: 'brandShareData',
      type: 'list',
      subFields: [
        {
          name: 'imageUrl',
          type: 'file',
          helperText: 'Upload an image for the brand',
          allowedFileTypes: ['jpeg', 'jpg', 'png']
        },
        {
          name: 'name',
          type: 'string',
          helperText: 'Enter the brand name'
        },
        {
          name: 'shareInfo',
          type: 'string',
          helperText: 'Enter share information'
        }
      ],
      helperText: 'Enter the brand share data with images, names, and information'
    },
    {
      name: 'headerAlignment',
      type: 'string',
      enum: ['left', 'center'],
      defaultValue: 'left',
      helperText: 'Horizontal alignment for the header'
    }
  ],
});

Builder.registerComponent(SubcatQuotes, {
  name: 'SubcatQuotes',
  inputs: [
    {
      name: 'quotesData',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'string',
          helperText: 'Enter the title for the quotes section'
        },
        {
          name: 'subtitle',
          type: 'string',
          helperText: 'Enter the subtitle for the quotes section'
        },
        {
          name: 'quotes',
          type: 'list',
          subFields: [
            {
              name: 'variant',
              type: 'string',
              enum: ['colored', 'transparent'],
              helperText: 'Select the variant color for the quote card'
            },
            {
              name: 'width',
              type: 'string',
              enum: ['narrow', 'wide'],
              helperText: 'Select the width for the quote card'
            },
            {
              name: 'quote',
              type: 'longText',
              helperText: 'Enter the quote text'
            }
          ],
          helperText: 'Add quotes with detailed attributes'
        }
      ],
      helperText: 'Add all quote data'
    },
    {
      name: 'subcatAccentColor',
      friendlyName: 'Colored Card Background',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the subcategory accent'
    },
    {
      name: 'onAccentTextColor',
      friendlyName: 'Colored Card Text Color',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Enter the color key for the text on the accent'
    },
    {
      name: 'subTextColor',
      friendlyName: 'Gray Card Text Color',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],      
      helperText: 'Enter the color key for the sub text'
    },
    {
      name: 'quoteIcon2',
      friendlyName: 'Colored CardQuote Icon',
      type: 'string',
      enum: [
        'WHITE',
        'BLACK',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE'
      ],
      helperText: 'Enter the key for the second quote icon'
    },
    {
      name: 'quoteIcon1',
      friendlyName: 'Gray Card Quote Icon',
      type: 'string',
      enum: [
        'WHITE',
        'BLACK',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE'
      ],
      helperText: 'Enter the key for the first quote icon'
    }
  ],
});

Builder.registerComponent(WrapUpSection, {
  name: 'WrapUpSection',
  inputs: [
    {
      name: 'wrapUpHeaderSubcat',
      type: 'string',
      helperText: 'Enter the header subcategory text'
    },
    {
      name: 'wrapUpStat1',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          helperText: 'Enter the first statistic'
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter the label for the first statistic'
        }
      ],
      helperText: 'Enter the first wrap-up statistic'
    },
    {
      name: 'wrapUpStat2',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          helperText: 'Enter the second statistic'
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter the label for the second statistic'
        }
      ],
      helperText: 'Enter the second wrap-up statistic'
    },
    {
      name: 'wrapUpDescription',
      type: 'object',
      subFields: [
        {
          name: 'highlightSubcat',
          type: 'string',
          helperText: 'Enter the highlighted subcategory text'
        },
        {
          name: 'description',
          type: 'longText',
          helperText: 'Enter the description text'
        }
      ],
      helperText: 'Enter the wrap-up description'
    },
    {
      name: 'wrapUpCardsText',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'string',
          helperText: 'Enter text for the card'
        }
      ],
      helperText: 'Enter the texts for wrap-up cards'
    },
    {
      name: 'wrapUpProductImage',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the product'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the product image'
        }
      ],
      helperText: 'Enter the product image details'
    },
    {
      name: 'subcatAccentColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],      
      helperText: 'Select the accent color for the subcategory'
    },
    {
      name: 'onAccentTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],      
      helperText: 'Select the text color that appears on accent color'
    },
    {
      name: 'highlightTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      defaultValue: 'TEXT_WHITE',
      helperText: 'Select the highlight text color'
    }
  ],
});

Builder.registerComponent(CategoryStats, {
  name: 'CategoryStats',
  inputs: [
    {
      name: 'headlineText',
      type: 'string',
      helperText: 'Enter the headline text for the category stats'
    },
    {
      name: 'amount',
      type: 'string',
      helperText: 'Enter the amount to display as the category size'
    },
    {
      name: 'chartImageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the chart image for category market share'
    },
    {
      name: 'chartImageAlt',
      type: 'string',
      defaultValue: '<CATEGORY> category market share donut chart',
      helperText: 'Enter the alt text for the chart image'
    },
    {
      name: 'sourceText',
      type: 'string',
      defaultValue: 'Source: IWSR 2023 FY Share of Dollars',
      helperText: 'Enter the source text for the category stats'
    }
  ],
});

Builder.registerComponent(TopBrandTable, {
  name: 'TopBrandTable',
  inputs: [
    {
      name: 'categoryStructure',
      type: 'richText',
      helperText: 'Enter the category structure text or HTML'
    },
    {
      name: 'topBrandsTable',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the image for the top brands table'
    },
    {
      name: 'topBrandsTableAltText',
      type: 'string',
      helperText: 'Enter the alt text for the top brands table image'
    },
    {
      name: 'sizeGrowthTable',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the image for the size growth table'
    },
    {
      name: 'sizeGrowthTableAltText',
      type: 'string',
      helperText: 'Enter the alt text for the size growth table image'
    }
  ],
});

Builder.registerComponent(SegmentExplorer, {
  name: 'SegmentExplorer',
  inputs: [
    {
      name: 'segmentCards',
      type: 'list',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload an image for the segment card'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter alt text for the segment card image'
        },
        {
          name: 'navigateTo',
          type: 'string',
          helperText: 'Enter the URL to navigate to when the card is clicked'
        }
      ],
      helperText: 'Define cards with images, alt texts, and navigation targets'
    }
  ],
});

Builder.registerComponent(CategoryHubHeader, {
  name: 'CategoryHubHeader',
  inputs: [
    {
      name: 'backgroundImageUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb647a3eb2a804215886b950080ac1d5a",
      helperText: 'Upload a background image for the header'
    },
    {
      name: 'title',
      type: 'string',
      helperText: 'Enter the title for the header'
    },
    {
      name: 'descriptionStart',
      type: 'string',
      helperText: 'Enter the initial part of the description'
    },
    {
      name: 'highlightedText',
      type: 'string',
      helperText: 'Enter the text to be highlighted in the description'
    },
    {
      name: 'descriptionEnd',
      type: 'string',
      helperText: 'Enter the concluding part of the description'
    }
  ],
});

Builder.registerComponent(CategoryMethod, {
  name: 'CategoryMethod',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Using Data to Define Key <CATEGORY> Segments',
      helperText: 'Enter the title for the method section'
    },
    {
      name: 'description',
      type: 'string',
      defaultValue: 'We utilized shopper and consumer data to understand how the <CATEGORY> Category is actually segmented.',
      helperText: 'Enter the description text for the method section'
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/024dd9b7ba639746e7e18081636842d3b158c6ce2c8b95cb72a05309f85c866a',
      helperText: 'Upload an image for the category method visualization'
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue: 'Category segmentation visualization',
      helperText: 'Enter the alt text for the image'
    }
  ],
});

Builder.registerComponent(SegmentDisplay, {
  name: 'SegmentDisplay',
  inputs: [
    {
      name: 'title',
      type: 'string',
      helperText: 'Enter the title text for the segment display section'
    },
    {
      name: 'segmentCount',
      type: 'string',
      helperText: 'Enter the text displaying the count of segments'
    },
    {
      name: 'segmentCards',
      type: 'list',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload an image for the segment card'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the segment card image'
        },
        {
          name: 'navigateTo',
          type: 'string',
          helperText: 'Enter the URL to navigate to when the card is clicked'
        }
      ],
      helperText: 'Add individual segment cards with image, alt text, and navigation targets'
    }
  ],
});

Builder.registerComponent(TripTypesHeader, {
  name: 'TripTypesHeader',
  inputs: [
    {
      name: 'heroImage',
      friendlyName: 'Hero Image URL',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1595f5613f414acca7616ff56693b726',
      helperText: 'Upload the header image for trip types page'
    },
    {
      name: 'title',
      friendlyName: 'Title Text',
      type: 'string',
      defaultValue: 'Trip Type Name',
      required: true,
    },
    {
      name: 'description',
      friendlyName: 'Description Text',
      type: 'longText',
      defaultValue: 'Enter a description for the trip type',
      required: true,
    },
    {
      name: 'stat1',
      friendlyName: 'Stat Medallion 1',
      type: 'object',
      defaultValue: {
        stat: 'X%',
        label: 'of Alc Trips',
      },
      subFields: [
        { name: 'stat', type: 'string', required: true },
        { name: 'label', type: 'string', required: true },
      ],
    },
    {
      name: 'stat2',
      friendlyName: 'Stat Medallion 2',
      type: 'object',
      defaultValue: {
        stat: 'X%',
        label: 'of Alc Spend',
      },
      subFields: [
        { name: 'stat', type: 'string', required: true },
        { name: 'label', type: 'string', required: true },
      ],
    },
    {
      name: 'tripTypeColor',
      friendlyName: 'Trip Type Theme Color',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      helperText: 'Select the highlight text color',
      required: true,
    },
  ],
});

Builder.registerComponent(SpendAnalytics, {
  name: 'SpendAnalytics',
  inputs: [
    {
      name: 'whatPeopleBuy',
      type: 'string',
      defaultValue: 'Enter what is bought',
      required: true,
    },
    {
      name: 'whyPeopleBuy',
      type: 'string',
      defaultValue: 'Enter why they buy',
      required: true,
    },
    {
      name: 'averageSpend',
      type: 'number',
      defaultValue: 0,
      required: true,
    },
    {
      name: 'averageSpendTag',
      type: 'number',
      defaultValue: 0,
      required: true,
    },
    {
      name: 'averageSpendTagColor',
      type: 'string',
      enum: [
        'GREEN_STAT_TAG',
        'RED_STAT_TAG',
        'GRAY_STAT_TAG',
      ],
      defaultValue: 'GREEN_STAT_TAG',
      required: true,
    },
    {
      name: 'alcoholShareOfSpend',
      type: 'number',
      defaultValue: 0,
      required: true,
    },
    {
      name: 'alcoholShareOfSpendTag',
      type: 'number',
      defaultValue: 0,
      required: true,
    },
    {
      name: 'alcoholShareOfSpendTagColor',
      type: 'string',
      enum: [
        'GREEN_STAT_TAG',
        'RED_STAT_TAG',
        'GRAY_STAT_TAG',
      ],
      defaultValue: 'GREEN_STAT_TAG',
      required: true,
    },
    {
      name: 'tableData',
      type: 'list',
      subFields: [
        { name: 'item', type: 'string', required: true },
        { name: 'percentage', type: 'number', required: true },
        { name: 'index', type: 'number', required: true },
        { 
          name: 'tagColor', 
          type: 'string', 
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
        },
      ],
      defaultValue: [
        { item: 'Product 1', percentage: 0, index: 0, tagColor: 'GREEN_STAT_TAG' },
        { item: 'Product 2', percentage: 0, index: 0, tagColor: 'GREEN_STAT_TAG' },
        { item: 'Product 3', percentage: 0, index: 0, tagColor: 'RED_STAT_TAG' },
        { item: 'Product 4', percentage: 0, index: 0, tagColor: 'RED_STAT_TAG' },
      ]
    },
    {
      name: 'footnote',
      type: 'boolean',
      defaultValue: true,
      helperText: 'Show footnote',
    },
  ],
});

Builder.registerComponent(ShoppingAnalytics, {
  name: 'ShoppingAnalytics',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'cardAlignment',
      type: 'string',
      defaultValue: 'GRAPH_LEFT',
      enum: ['GRAPH_LEFT', 'GRAPH_RIGHT'],
      required: true,
    },
    {
      name: 'underlineWord',
      type: 'string',
      defaultValue: 'Where',
      required: true,
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'people are shopping',
      required: true,
    },
    {
      name: 'chartTitle',
      type: 'string',
      defaultValue: 'CHANNEL TRIP DISTRIBUTION',
      required: true,
    },
    {
      name: 'chartImgUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: false,
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/86fd61d15fad31b7d546754fbbe9799d17187a386ff1352ae3bb330ccedfa200',
      helperText: 'Upload the chart image'
    },
    {
      name: 'legendData',
      type: 'list',
      subFields: [
        { 
          name: 'color', 
          type: 'string', 
          required: true,
          enum: [
            'BRIGHT_YELLOW_KEY',
            'MID_YELLOW_KEY',
            'DRK_YELLOW_KEY',
            'WHITE_KEY',
            'GRAY_KEY',
          ],
        },
        { name: 'label', type: 'string', required: true },
        { name: 'percentage', type: 'number', required: true },
        { name: 'count', type: 'number', required: true },
        { 
          name: 'tagColor', 
          type: 'string', 
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
        },
      ],
      defaultValue: [
        { color: 'BRIGHT_YELLOW_KEY', label: 'C-Store', percentage: 0, count: 0, tagColor: 'GREEN_STAT_TAG' },
        { color: 'MID_YELLOW_KEY', label: 'Liquor', percentage: 0, count: 0, tagColor: 'GRAY_STAT_TAG' },
        { color: 'DRK_YELLOW_KEY', label: 'Grocery', percentage: 0, count: 0, tagColor: 'RED_STAT_TAG' },
        { color: 'WHITE_KEY', label: 'Mass & Club', percentage: 0, count: 0, tagColor: 'RED_STAT_TAG' },
        { color: 'GRAY_KEY', label: 'All Other', percentage: 0, count: 0, tagColor: 'GRAY_STAT_TAG' },
      ],
    },
    {
      name: 'themeTitle',
      type: 'string',
      defaultValue: 'Store Choice themes',
      required: true,
    },
    {
      name: 'themesTexts',
      friendlyName: 'Full Themes Texts',
      helperText: 'Enter the full text for each theme',
      type: 'list',
      subFields: [{ name: 'theme', type: 'text', required: true }],
      defaultValue: [
        {theme: 'Text from Full Themes Texts, Highlights from Highlighted Excerpt Texts'},
        {theme: 'Simple as that! You can do it!'},
        {theme: "Just more default example text. OK, I'm done now."}
      ],
    },
    {
      name: 'highlightThemesTexts',
      friendlyName: 'Highlighted Excerpt Texts',
      helperText: 'Enter the highlighted excerpt text for each theme',
      type: 'list',
      subFields: [{ name: 'text', type: 'text' }],
      defaultValue: [
        {text: 'Full Themes Texts'},
        {text: 'Highlighted Excerpt Texts'},
        {text: 'You can do it'},
        {text: 'example text'}
      ],
    },
  ],
});

Builder.registerComponent(SectionHeader, {
  name: 'SectionHeader',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Section Title',
      required: true,
      helperText: 'Enter the main title for the section'
    },
    {
      name: 'description',
      type: 'string',
      defaultValue: 'Enter the description for the content in this section.',
      required: true,
      helperText: 'Enter the description text that appears below the title'
    },
    {
      name: 'takeawayData',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter the full text for each takeaway'
        }
      ],
      defaultValue: [
        { text: "Key takeaways, with highlighted core points" },
        { text: "More default text to demo highlighting" }
      ],
      helperText: 'Add the key takeaway statements'
    },
    {
      name: 'takeawayHighlightTexts',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter the text that should be highlighted within the takeaways'
        }
      ],
      defaultValue: [
        { text: 'highlighted core points' },
        { text: 'demo highlighting' }
      ],
      helperText: 'Add the portions of text that should be highlighted in the takeaways'
    }
  ],
});

Builder.registerComponent(CommonThemes, {
  name: 'CommonThemes',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'themeData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'showThemeCard',
          type: 'boolean',
          defaultValue: true,
          helperText: 'Show the theme card'
        },
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the theme title'
        },
        {
          name: 'cards',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'imageUrl',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              helperText: 'Upload or select an image for the card'
            },
            {
              name: 'text',
              type: 'string',
              required: true,
              helperText: 'Enter the main text content for the card'
            },
            {
              name: 'highlight',
              type: 'list',
              subFields: [
                {
                  name: 'text',
                  type: 'text',
                  required: true,
                  helperText: 'Enter text to be highlighted within the main content'
                }
              ],
              helperText: 'Add words or phrases to be highlighted'
            }
          ],
          helperText: 'Add content cards for this theme'
        }
      ],
      defaultValue: [
        {
          title: "Theme Title Goes Here",
          cards: [
            {
              imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F145922ff45614b5d9e9e3aa177cad14a",
              text: "Add a photo caption with highlighted key theme",
              highlight: [{text: "highlighted key theme"}]
            }
          ]
        }
      ],
      helperText: 'Add themes and their associated content cards'
    }
  ]
});

Builder.registerComponent(TestimonialCardSection, {
  name: 'TestimonialCardSection',
  inputs: [
    {
      name: 'sectionName',
      type: 'string',
      defaultValue: 'section name',
      required: true,
      helperText: 'Enter the name of the section'
    },
    {
      name: 'testimonialTitle',
      type: 'string',
      defaultValue: 'in their own words',
      required: true,
      helperText: 'Enter the title for the testimonials section'
    },
    {
      name: 'testimonialData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'cardWidth',
          type: 'string',
          enum: ['HALF_WIDTH', 'FULL_WIDTH'],
          required: true,
          helperText: 'Select the width of the testimonial cards'
        },
        {
          name: 'quote',
          type: 'longText',
          required: true,
          helperText: 'Enter the testimonial quote (~130 characters or less for half width cards)'
        },
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the name of the person'
        },
        {
          name: 'age',
          type: 'string',
          required: true,
          helperText: 'Enter the age of the person'
        },
        {
          name: 'sex',
          type: 'string',
          enum: ['Male', 'Female', 'Other'],
          required: true,
          helperText: 'Select the gender of the person'
        },
        {
          name: 'location',
          type: 'string',
          required: true,
          helperText: 'Enter the location of the person'
        },
        {
          name: 'callout',
          type: 'string',
          required: false,
          helperText: 'Enter the on photo caption text (use \\n for line breaks)'
        },
        {
          name: 'cardAlignment',
          type: 'string',
          enum: ['IMAGE_RIGHT', 'IMAGE_LEFT'],
          required: true,
          helperText: 'Select the alignment of the image and quote'
        },
        {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
          helperText: 'Upload the testimonial image'
        }
      ],
      defaultValue: [
        {
          cardWidth: "HALF_WIDTH",
          quote: "Add the quote here to display an example. Keep quotes concise. Use this placeholder as a guide to select a quote of similar length.",
          name: "Michael J",
          age: "23",
          sex: "Male",
          location: "Chicago, IL",
          callout: "image \\n caption",
          cardAlignment: "IMAGE_RIGHT",
          imageSrc: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F63bbf441995a454ca30f50a624707769"
        }
      ],
      helperText: 'Add testimonial cards with their content and images'
    }
  ]
});

Builder.registerComponent(ChannelSelection, {
  name: 'ChannelSelection',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'selectionDescription',
      type: 'string',
      defaultValue: 'The description text for the channel selection goes here',
      required: true,
      helperText: 'Enter the description text for the channel selection'
    },
    {
      name: 'chartImgUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/289bc3d4e215898429ff948daa4208465863d39b370f6422ef27ae07ddb894a7',
      helperText: 'Upload the chart image for channel distribution'
    },
    {
      name: 'channelData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'label',
          type: 'string',
          required: true,
          helperText: 'Enter the channel name'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage value for this channel'
        },
        {
          name: 'count',
          type: 'number',
          required: true,
          helperText: 'Enter the count value for this channel'
        },
        { 
          name: 'color', 
          type: 'string', 
          required: true,
          enum: [
            'BRIGHT_YELLOW_KEY',
            'MID_YELLOW_KEY',
            'DRK_YELLOW_KEY',
            'WHITE_KEY',
            'GRAY_KEY',
          ],
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
        }
      ],
      defaultValue: [
        {
          label: 'C-Store',
          percentage: 0,
          count: 0,
          color: 'BRIGHT_YELLOW_KEY',
          tagColor: 'GREEN_STAT_TAG'
        },
        {
          label: 'Liquor',
          percentage: 0,
          count: 0,
          color: 'MID_YELLOW_KEY',
          tagColor: 'GRAY_STAT_TAG'
        }
      ],
      helperText: 'Add channel data with labels, percentages, counts, and colors'
    }
  ]
});

Builder.registerComponent(StoreTopFactors, {
  name: 'StoreTopFactors',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'sectionName',
      type: 'string',
      defaultValue: 'section name',
      required: true,
      helperText: 'Enter the name of the section'
    },
    {
      name: 'factorSectionTitle',
      type: 'string',
      defaultValue: 'Top Factors Title Goes Here',
      required: true,
      helperText: 'Enter the title for the top factors section'
    },
    {
      name: 'factor1Title',
      type: 'string',
      defaultValue: 'Factor 1 Title Goes Here',
      required: true,
      helperText: 'Enter the title for the first factor group'
    },
    {
      name: 'factor1Data',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload an icon for this factor'
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter the description text for this factor'
        }
      ],
      defaultValue: [
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/0e8b222888bf69ba2d4b8e43fb206630506721cf80a4d5663110ba17f0b9105c",
          text: "Factor 1.1 Keypoint w/ Icon Option"
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/256feddf4297630f38def1054fbbc325481589393314343bb23cde44cf3ad0d5",
          text: "Factor 1.2 Keypoint w/ Icon Option"
        }
      ],
      helperText: 'Add convenience factors with icons and descriptions'
    },
    {
      name: 'factor2Title',
      type: 'string',
      defaultValue: 'Factor 2 Title Goes Here',
      required: true,
      helperText: 'Enter the title for the second factor group'
    },
    {
      name: 'factor2Data',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload an icon for this factor'
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter the description text for this factor'
        }
      ],
      defaultValue: [
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/0c444b7a448046b8148f905225ebced779d7f1241133db25b2d6656b2e04fdfc",
          text: "Factor 2.1 Keypoint w/ Icon Option"
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/122a6c98bbfb1006bceb69aa3f339ce0ea9e0f9186ef163ac259bcfe96f1f093",
          text: "Factor 2.2 Keypoint w/ Icon Option"
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/5fc5049f960f3cbd1f4db2e1366af603d5fc3003ca3377367f41d5d7c6edcb7d",
          text: "Factor 2.3 Keypoint w/ Icon Option"
        }
      ],
      helperText: 'Add product format factors with icons and descriptions'
    }
  ]
});

Builder.registerComponent(StoreTopFactors2, {
  name: 'StoreTopFactors2',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'ALC_RELOAD_TEAL',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'sectionName',
      type: 'string',
      defaultValue: 'section name',
      required: true,
      helperText: 'Enter the name of the section'
    },
    {
      name: 'factorSectionTitle',
      type: 'string',
      defaultValue: 'Top Factors Title Goes Here',
      required: true,
      helperText: 'Enter the title for the top factors section'
    },
    {
      name: 'factorsData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the title for this factor'
        },
        {
          name: 'points',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'text',
              type: 'string',
              required: true,
              helperText: 'Enter a key point for this factor'
            }
          ],
          helperText: 'Add key points for this factor'
        }
      ],
      defaultValue: [
        {
          title: 'Factor 1 Title',
          points: [
            { text: 'Factor 1.1 Keypoint' },
            { text: 'Factor 1.2 Keypoint' }
          ]
        },
        {
          title: 'Factor 2 Title',
          points: [
            { text: 'Factor 2.1 Keypoint' },
            { text: 'Factor 2.2 Keypoint' }
          ]
        },
        {
          title: 'Factor 3 Title',
          points: [
            { text: 'Factor 3.1 Keypoint' },
            { text: 'Factor 3.2 Keypoint' }
          ]
        }
      ],
      helperText: 'Add factors with their titles and key points'
    }
  ]
});

Builder.registerComponent(BasketComposition, {
  name: 'BasketComposition',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'basketData',
      type: 'object',
      subFields: [
        {
          name: 'totalSpend',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'amount',
              type: 'number',
              required: true,
              helperText: 'Enter the total spend amount'
            },
            {
              name: 'index',
              type: 'number',
              required: true,
              helperText: 'Enter the index value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
            }
          ]
        },
        {
          name: 'alcShare',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'amount',
              type: 'number',
              required: true,
              helperText: 'Enter the alcohol share amount'
            },
            {
              name: 'index',
              type: 'number',
              required: true,
              helperText: 'Enter the index value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
            }
          ]
        }
      ],
      defaultValue: {
        totalSpend: {
          amount: 0,
          index: 0,
          tagColor: "RED_STAT_TAG"
        },
        alcShare: {
          amount: 0,
          index: 0,
          tagColor: "GREEN_STAT_TAG"
        }
      },
      helperText: 'Enter the basket data statistics'
    },
    {
      name: 'statsData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'amount',
          type: 'number',
          required: true,
          helperText: 'Enter the statistic amount'
        },
        {
          name: 'index',
          type: 'number',
          required: true,
          helperText: 'Enter the index value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
        },
        {
          name: 'label',
          type: 'string',
          required: true,
          helperText: 'Enter the label text'
        }
      ],
      defaultValue: [
        {
          amount: 0,
          index: 0,
          tagColor: "RED_STAT_TAG",
          label: "Alcohol Spend"
        },
        {
          amount: 0,
          index: 0,
          tagColor: "RED_STAT_TAG",
          label: "Alcohol Items"
        }
      ],
      helperText: 'Enter the statistics data'
    },
    {
      name: 'nonAlcItemCount',
      type: 'number',
      defaultValue: 0,
      required: true,
      helperText: 'Enter the number of non-alcoholic items'
    },
    {
      name: 'categoryData',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the category name'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage value'
        },
        {
          name: 'index',
          type: 'number',
          required: true,
          helperText: 'Enter the index value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
        }
      ],
      defaultValue: [
        { name: "Non-Alc Item 1", percentage: 0, index: 0, tagColor: "GREEN_STAT_TAG" },
        { name: "Non-Alc Item 2", percentage: 0, index: 0, tagColor: "GREEN_STAT_TAG" },
        { name: "Non-Alc Item 3", percentage: 0, index: 0, tagColor: "RED_STAT_TAG" },
        { name: "Non-Alc Item 4", percentage: 0, index: 0, tagColor: "RED_STAT_TAG" }
      ],
      helperText: 'Enter the category data'
    },
    {
      name: 'tableFooter',
      type: 'boolean',
      defaultValue: true,
      helperText: 'Show table footnote',
    }
  ]
});

Builder.registerComponent(BasketStats, {
  name: 'BasketStats',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'categoryData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'beverageCategory',
          type: 'string',
          enum: [
            'BEER',
            'SPIRITS',
            'WINE',
            'HARD_BEV',
          ],
          required: true,
          helperText: 'Select a beverage category'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage of trips'
        },
        {
          name: 'count',
          type: 'number',
          required: true,
          helperText: 'Enter the number of trips'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
          helperText: 'Select the tag color'
        },
        {
          name: 'basketPercentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage of basket'
        }
      ],
      defaultValue: [
        {
          beverageCategory: 'BEER',
          percentage: 0,
          count: 0,
          tagColor: 'GREEN_STAT_TAG',
          basketPercentage: 0
        },
        {
          beverageCategory: 'SPIRITS',
          percentage: 0,
          count: 0,
          tagColor: 'RED_STAT_TAG',
          basketPercentage: 0
        },
        {
          beverageCategory: 'WINE',
          percentage: 0,
          count: 0,
          tagColor: 'RED_STAT_TAG',
          basketPercentage: 0
        },
        {
          beverageCategory: 'HARD_BEV',
          percentage: 0,
          count: 0,
          tagColor: 'RED_STAT_TAG',
          basketPercentage: 0
        }
      ],
      helperText: 'Add categories with their statistics and images'
    }
  ]
});

Builder.registerComponent(BasketCategoryStats, {
  name: 'BasketCategoryStats',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'statCardData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'categoryTitle',
          type: 'string',
          required: true,
          enum: [
            'BEER',
            'SPIRITS',
            'WINE',
            'HARD_BEV',
          ],
          helperText: 'Select a beverage category'
        },
        {
          name: 'statGroup',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'stat1',
              type: 'object',
              required: true,
              subFields: [
                { name: 'value', type: 'number', required: true },
                { name: 'tag', type: 'number', required: true },
                {
                  name: 'tagColor',
                  type: 'string',
                  required: true,
                  enum: [
                    'GREEN_STAT_TAG',
                    'RED_STAT_TAG',
                    'GRAY_STAT_TAG',
                  ],
                  helperText: 'Select the tag color'
                },
              ]
            },
            {
              name: 'stat2',
              type: 'object',
              required: true,
              subFields: [
                { name: 'value', type: 'number', required: true },
                { name: 'tag', type: 'number', required: true },
                {
                  name: 'tagColor',
                  type: 'string',
                  required: true,
                  enum: [
                    'GREEN_STAT_TAG',
                    'RED_STAT_TAG',
                    'GRAY_STAT_TAG',
                  ],
                  helperText: 'Select the tag color'
                },
              ]
            }
          ]
        },
        {
          name: 'metricGroup',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'metric1',
              type: 'object',
              required: true,
              subFields: [
                { name: 'value', type: 'number', required: true },
                { name: 'label', type: 'string', required: true }
              ]
            },
            {
              name: 'metric2',
              type: 'object',
              required: true,
              subFields: [
                { name: 'value', type: 'number', required: true },
                { name: 'label', type: 'string', required: true }
              ]
            }
          ]
        }
      ],
      defaultValue: {
        categoryTitle: "BEER",
        statGroup: {
          stat1: {
            value: 62,
            tag: 168,
            tagColor: 'GREEN_STAT_TAG'
          },
          stat2: {
            value: 13,
            tag: 83,
            tagColor: 'RED_STAT_TAG'
          }
        },
        metricGroup: {
          metric1: {
            value: 71,
            label: "Frequency in Basket"
          },
          metric2: {
            value: 1.2,
            label: "Avg Beer Items"
          }
        }
      }
    },
    {
      name: 'segmentTableData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the segment name'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage value'
        },
        {
          name: 'index',
          type: 'number',
          required: true,
          helperText: 'Enter the index value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
          helperText: 'Select the tag color'
        },
      ],
      defaultValue: [
        { name: 'Light Lager', percentage: 49, index: 112, tagColor: 'GRAY_STAT_TAG' },
        { name: 'Classic Lager', percentage: 33, index: 98, tagColor: 'GRAY_STAT_TAG' },
        { name: 'Balanced Choices Lager', percentage: 10, index: 93, tagColor: 'GRAY_STAT_TAG' },
        { name: 'IPA / Pale Ale', percentage: 3, index: 64, tagColor: 'RED_STAT_TAG' },
        { name: 'Cheladas', percentage: 2, index: 125, tagColor: 'GREEN_STAT_TAG' },
        { name: 'Wheat', percentage: 1, index: 67, tagColor: 'RED_STAT_TAG' },
        { name: 'Non-Alc Beer', percentage: 1, index: 53, tagColor: 'RED_STAT_TAG' },
        { name: 'Craft Easy Drinking', percentage: 1, index: 62, tagColor: 'RED_STAT_TAG' }
      ]
    },
    {
      name: 'packSizeTableData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the pack size name'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage value'
        },
        {
          name: 'index',
          type: 'number',
          required: true,
          helperText: 'Enter the index value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
          helperText: 'Select the tag color'
        },
      ],
      defaultValue: [
        { name: 'Single (1) Can/Bottle', percentage: 14, index: 143, tagColor: 'GREEN_STAT_TAG' },
        { name: 'Small pack (i.e., 4-6 pack)', percentage: 14, index: 102, tagColor: 'GRAY_STAT_TAG' },
        { name: 'Medium pack (i.e., 8-12 pack)', percentage: 30, index: 100, tagColor: 'GRAY_STAT_TAG' },
        { name: 'Large pack (i.e., 18-36 pack)', percentage: 40, index: 98, tagColor: 'GRAY_STAT_TAG' }
      ]
    },
    {
      name: 'priceTierTableData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the price tier name'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage value'
        },
        {
          name: 'index',
          type: 'number',
          required: true,
          helperText: 'Enter the index value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
          helperText: 'Select the tag color'
        },
      ],
      defaultValue: [
        { name: 'Value', percentage: 24, index: 124, tagColor: 'GREEN_STAT_TAG' },
        { name: 'Core', percentage: 44, index: 108, tagColor: 'GRAY_STAT_TAG' },
        { name: 'Core+', percentage: 12, index: 98, tagColor: 'GRAY_STAT_TAG' },
        { name: 'Premium', percentage: 17, index: 78, tagColor: 'RED_STAT_TAG' },
        { name: 'Super Premium', percentage: 4, index: 57, tagColor: 'RED_STAT_TAG' }
      ]
    }
  ]
});

Builder.registerComponent(ShopperBasketCarousel, {
  name: 'ShopperBasketCarousel',
  inputs: [
    {
      name: 'testimonials',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'quote',
          type: 'string',
          required: true,
          helperText: 'Enter the testimonial quote'
        },
        {
          name: 'author',
          type: 'string',
          required: true,
          helperText: 'Enter the author name'
        },
        {
          name: 'age',
          type: 'string',
          required: true,
          helperText: 'Enter the author age'
        },
        {
          name: 'sex',
          type: 'string',
          enum: ['Male', 'Female'],
          required: true,
          helperText: 'Select the author gender'
        },
        {
          name: 'location',
          type: 'string',
          required: true,
          helperText: 'Enter the author location'
        },
        {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the testimonial image'
        },
        {
          name: 'iconSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the testimonial icon'
        }
      ],
      defaultValue: [
        {
          quote: "Just my usual tall can I have while cooking dinner.",
          author: "Rafael G",
          age: "47",
          sex: "Male",
          location: "Sacramento, CA",
          imageSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/a2b0399bd8610ffeed69993c50ff05e6ce4fafc9474a1a1ee4ef324e75bcaa2f",
          iconSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/2d8a929721a45ca41e8d49601c29991a59fdd071c5d34abe88ef1fa94d65b401"
        },
        {
          quote: "Drinks for the day! Twisted Tea for my husband and for me, a hard tea for after dinner or even during.",
          author: "Jessica B",
          age: "34",
          sex: "Female",
          location: "Rochester, NY",
          imageSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/37975a05f26ef79f429b84829731a26297828ff402965070980f46a7d9d05437",
          iconSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/2d8a929721a45ca41e8d49601c29991a59fdd071c5d34abe88ef1fa94d65b401"
        },
        {
          quote: "Summer Shandy I am purchasing to consume later tonight.",
          author: "Ellie O",
          age: "28",
          sex: "Female",
          location: "Cincinnati, OH",
          imageSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/b7a23c65c09fb41b0b73c943ecee907870f76fdd50646d4731c5e4dd1711dfe5",
          iconSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/2d8a929721a45ca41e8d49601c29991a59fdd071c5d34abe88ef1fa94d65b401"
        },
        {
          quote: "Ran in to just grab a drink.",
          author: "Jen C",
          age: "28",
          sex: "Female",
          location: "Cincinnati, OH",
          imageSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/441f6c0de9ec7b2df6464479e1a8bf5db7d55a04cc3002703de56fef866d8f56",
          iconSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/2d8a929721a45ca41e8d49601c29991a59fdd071c5d34abe88ef1fa94d65b401"
        },
        {
          quote: "Lime High Noons and a water bottle.",
          author: "Ellie O",
          age: "21",
          sex: "Male",
          location: "Cincinnati, OH",
          imageSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/66c064e92c741087d319afde8e594a7cec6dc87009491a6bb15b6d9fbef37df9",
          iconSrc: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/2d8a929721a45ca41e8d49601c29991a59fdd071c5d34abe88ef1fa94d65b401"
        }
      ],
      helperText: 'Add testimonials with customer quotes and details'
    },
    {
      name: 'cartImages',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'imageUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload a cart image'
        }
      ],
      defaultValue: [
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0833fb60b8ce48fabac66235cc8f901e"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff75f09aa788b466ab004dd7c7aab6103"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F989c584d93724d96b973ddc16037fca6"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fac299390e7a547dba5390dc0ae650e7c"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F4539eded9b024d9f9895660d81bf1685"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd11db211589c4177b8f34f733442fc9e"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0c24c0ea30f444c79ea0e5c5bb91caca"},
        {imageUrl: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0ab700e5815346499348c4d112b85917"}
      ],
      helperText: 'Add images of shopping carts'
    }
  ]
});

Builder.registerComponent(TripDuration, {
  name: 'TripDuration',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'duration',
      type: 'string',
      required: true,
      defaultValue: 'X',
      helperText: 'Enter the average trip duration'
    },
    {
      name: 'description',
      type: 'string',
      required: true,
      defaultValue: 'Enter a description of shopper speed and duration of time in store',
      helperText: 'Enter the duration description'
    },
    {
      name: 'tripDescription',
      type: 'string',
      required: true,
      defaultValue: 'Enter a description of the trip intent and shopper motivation',
      helperText: 'Enter the trip description'
    },
    {
      name: 'chartImgUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: false,
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F73f5fbe06e1b475bbf781cdf272fca59',
      helperText: 'Upload the chart image (optional)'
    }
  ]
});

Builder.registerComponent(ShopperDemographics, {
  name: 'ShopperDemographics',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'demographicsData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the demographic category title'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the demographic chart image'
        }
      ],
      defaultValue: [
        {
          title: 'Gender*',
          image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fe826d5d17d804283baf9bf23e8e252dc"
        },
        {
          title: 'Living Area',
          image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F95d93784ecc243b09a802d940f58ae9b"
        },
        {
          title: 'Age**',
          image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F4cc7b81f527c4c82aa041451704ab10a"
        },
        {
          title: 'Ethnicity',
          image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9202206d834e45c6a550b08664f97877"
        },
        {
          title: 'Income',
          image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F28b8e7b975844989850f50d6c14e4ec5"
        },
        {
          title: 'Key Takeaways',
          image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1931f6fd149e42f19c44d1dc4b81fcae"
        }
      ],
      helperText: 'Add demographic categories with their chart images'
    }
  ]
});

Builder.registerComponent(ShoppersVsConsumers, {
  name: 'ShoppersVsConsumers',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'chartImgUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: false,
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F08042c11bdc241dbab3548a23b34e90e',
      helperText: 'Upload the chart image'
    },
    {
      name: 'shopperVsConsumerCopy',
      type: 'string',
      required: true,
      defaultValue: 'Enter the description for who shoppers intend to purchase for on this trip type',
      helperText: 'Enter the main copy text'
    },
    {
      name: 'shopperVsConsumerBold',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'text',
          required: true,
          helperText: 'Enter the phrases to be bolded in the main copy'
        }
      ],
      defaultValue: [{text: 'who shoppers intend to purchase for'}],
    },
    {
      name: 'personaData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'topLeft',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'imageUrl',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              helperText: 'Upload the persona image'
            },
            {
              name: 'title',
              type: 'string',
              required: true,
              helperText: 'Enter the persona title'
            },
            {
              name: 'percentage',
              type: 'number',
              required: true,
              helperText: 'Enter the percentage value'
            },
            {
              name: 'count',
              type: 'number',
              required: true,
              helperText: 'Enter the count value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
              helperText: 'Select the tag color'
            }
          ]
        },
        {
          name: 'topRight',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'imageUrl',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              helperText: 'Upload the persona image'
            },
            {
              name: 'title',
              type: 'string',
              required: true,
              helperText: 'Enter the persona title'
            },
            {
              name: 'percentage',
              type: 'number',
              required: true,
              helperText: 'Enter the percentage value'
            },
            {
              name: 'count',
              type: 'number',
              required: true,
              helperText: 'Enter the count value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
              helperText: 'Select the tag color'
            }
          ]
        },
        {
          name: 'bottomLeft',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'imageUrl',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              helperText: 'Upload the persona image'
            },
            {
              name: 'title',
              type: 'string',
              required: true,
              helperText: 'Enter the persona title'
            },
            {
              name: 'percentage',
              type: 'number',
              required: true,
              helperText: 'Enter the percentage value'
            },
            {
              name: 'count',
              type: 'number',
              required: true,
              helperText: 'Enter the count value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
              helperText: 'Select the tag color'
            }
          ]
        },
        {
          name: 'bottomRight',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'imageUrl',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              helperText: 'Upload the persona image'
            },
            {
              name: 'title',
              type: 'string',
              required: true,
              helperText: 'Enter the persona title'
            },
            {
              name: 'percentage',
              type: 'number',
              required: true,
              helperText: 'Enter the percentage value'
            },
            {
              name: 'count',
              type: 'number',
              required: true,
              helperText: 'Enter the count value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
              helperText: 'Select the tag color'
            },
          ]
        }
      ],
      defaultValue: {
        topLeft: [
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/bdfd401c0daf1d9dd1ca2d40b14691964a398d6f13bac7035dc8a294d1b5c4cd",
            title: "Carefree Fun-Lovers",
            percentage: 0,
            count: 0,
            tagColor: "GREEN_STAT_TAG"
          },
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/75b9d1558e1304d7199877deba01a9b6b002c4653b678ebca2e304df0848082d",
            title: "Proud Celebrators",
            percentage: 0,
            count: 0,
            tagColor: "GRAY_STAT_TAG"
          }
        ],
        topRight: [
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/fd590eb76c3bef5a59241a3da4492258ec2ce5fc244a200ac8591edae87f8384",
            title: "Creative Explorers",
            percentage: 0,
            count: 0,
            tagColor: "GRAY_STAT_TAG"
          },
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/82bb615578501b11fb7e2839b5c70ba36f634db716e5af532a4e5fc0d03abe23",
            title: "Social Curators",
            percentage: 0,
            count: 0,
            tagColor: "RED_STAT_TAG"
          }
        ],
        bottomLeft: [
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/98de4d774b527ea00951b7f228ebc32c15a3ad5e1e64a0cc9f9b2e38920c71a7",
            title: "Comfort Cravers",
            percentage: 0,
            count: 0,
            tagColor: "GREEN_STAT_TAG"
          },
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/cce991c048454d60ad67b363e4eeb97e3a0dde2e0a46cd0f4dc06c364e87f0c0",
            title: "Loyal Traditionalists",
            percentage: 0,
            count: 0,
            tagColor: "GREEN_STAT_TAG"
          }
        ],
        bottomRight: [
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/902fbfb229708f026a5cbfbc7a5cb00e8204e079cf34cd0ecae14f74a0694e15",
            title: "Balanced Enjoyers",
            percentage: 0,
            count: 0,
            tagColor: "GRAY_STAT_TAG"
          },
          {
            imageUrl: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/aa41bd20a64fefea9e5cab03363b577d5ecb35c6ff32ace747581e9df53e9ef7",
            title: "Diligent Discoverers",
            percentage: 12,
            count: 86,
            tagColor: "GRAY_STAT_TAG"
          }
        ]
      }
    }
  ]
});

Builder.registerComponent(WeekpartDaypart, {
  name: 'WeekpartDaypart',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'weekpartText',
      type: 'longText',
      required: true,
      defaultValue: "This is the Weekpart & Daypart description section. \\n\\nThe copy in this section is used to describe what time of day and when during the week shoppers are most likely to make these types of trips.",
      helperText: 'Enter the weekpart description text (use \\n for line breaks)'
    },
    {
      name: 'boldTexts',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter text to be bolded'
        }
      ],
      defaultValue: [
        {text: "Weekpart & Daypart"}, 
        {text: "what time of day and when during the week"}
      ],
      helperText: 'Add phrases that should appear in bold'
    },
    {
      name: 'headerData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'columns',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'title',
              type: 'string',
              required: true,
              helperText: 'Enter the column title'
            },
            {
              name: 'percentage',
              type: 'number',
              required: true,
              helperText: 'Enter the percentage value'
            },
            {
              name: 'tag',
              type: 'number',
              required: true,
              helperText: 'Enter the tag value'
            },
            {
              name: 'tagColor',
              type: 'string',
              required: true,
              enum: [
                'GREEN_STAT_TAG',
                'RED_STAT_TAG',
                'GRAY_STAT_TAG',
              ],
              helperText: 'Select the tag color'
            },
          ]
        }
      ],
      defaultValue: {
        columns: [
          { title: 'Mon-Thurs', percentage: 47, tag: 110, tagColor: 'GRAY_STAT_TAG' },
          { title: 'Fri', percentage: 24, tag: 98, tagColor: 'GRAY_STAT_TAG' },
          { title: 'Sat', percentage: 19, tag: 88, tagColor: 'GRAY_STAT_TAG' },
          { title: 'Sun', percentage: 10, tag: 90, tagColor: 'GRAY_STAT_TAG' }
        ]
      }
    },
    {
      name: 'dayPartData',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the daypart title'
        },
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the overall percentage'
        },
        {
          name: 'tag',
          type: 'number',
          required: true,
          helperText: 'Enter the tag value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
          helperText: 'Select the tag color'
        },
        {
          name: 'cells',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'value',
              type: 'number',
              required: true,
              helperText: 'Enter the cell percentage'
            }
          ]
        }
      ],
      defaultValue: [
        {
          title: 'Morning',
          percentage: 10,
          tag: 87,
          tagColor: 'GRAY_STAT_TAG',
          cells: [{value: 4}, {value: 2}, {value: 2}, {value: 1}]
        },
        {
          title: 'Midday',
          percentage: 18,
          tag: 81,
          tagColor: 'RED_STAT_TAG',
          cells: [{value: 8}, {value: 4}, {value: 4}, {value: 2}]
        },
        {
          title: 'Afternoon',
          percentage: 29,
          tag: 101,
          tagColor: 'GRAY_STAT_TAG',
          cells: [{value: 14}, {value: 8}, {value: 5}, {value: 3}]
        },
        {
          title: 'Evening',
          percentage: 28,
          tag: 106,
          tagColor: 'GRAY_STAT_TAG',
          cells: [{value: 15}, {value: 6}, {value: 5}, {value: 2}]
        },
        {
          title: 'Late Night',
          percentage: 15,
          tag: 135,
          tagColor: 'GREEN_STAT_TAG',
          cells: [{value: 7}, {value: 4}, {value: 3}, {value: 1}]
        }
      ],
      helperText: 'Add daypart data rows'
    }
  ]
});

Builder.registerComponent(TimeToConsumption, {
  name: 'TimeToConsumption',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'timeToConsumptionData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the title for time to consumption'
        },
        {
          name: 'descriptionText',
          type: 'string',
          required: true,
          helperText: 'Enter the description for time to consumption'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the time to consumption chart image'
        }
      ],
      defaultValue: {
        descriptionText: "This section describes when the consumer intends to consume the products they purchased",
        title: "Time to Consumption",
        image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7aa51325058e4980b4e18802a9712b9e"
      }
    },
    {
      name: 'consumptionOccasionsData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the title for consumption occasions'
        },
        {
          name: 'descriptionText',
          type: 'string',
          required: true,
          helperText: 'Enter the description for consumption occasions'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the consumption occasions chart image'
        }
      ],
      defaultValue: {
        descriptionText: "This section describes the type of occasion in which the purchased product is intended to be consumed",
        title: "Intended Consumption Occasions",
        image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F19da545b86cf4e2186409d335ef7f150"
      }
    }
  ]
});

Builder.registerComponent(ShopperJourney, {
  name: 'ShopperJourney',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'profile1CardData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the profile image'
        },
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the profile name'
        },
        {
          name: 'description',
          type: 'string',
          required: true,
          helperText: 'Enter the profile description (age, ethnicity, location)'
        },
        {
          name: 'relationshipStatus',
          type: 'string',
          required: true,
          helperText: 'Enter the relationship status'
        },
        {
          name: 'drinkerType',
          type: 'string',
          enum: [
            'Carefree Fun-Lover',
            'Proud Celebrator',
            'Creative Explorer',
            'Social Curator',
            'Comfort Craver',
            'Loyal Traditionalist',
            'Balanced Enjoyer',
            'Diligent Discoverer'
          ],
          defaultValue: 'Comfort Craver',
          required: true,
          helperText: 'Select the drinker type'
        }
      ],
      defaultValue: {
        image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/8ed257d2ee30429b041c68ae6ab16584c71d76f97a663b9e2bf6ff654fba0fbd",
        name: "Matthew D.",
        description: "21 | White | Cincinnati, OH",
        relationshipStatus: "Single",
        drinkerType: "Comfort Craver"
      }
    },
    {
      name: 'purposeContent1',
      type: 'longText',
      required: true,
      defaultValue: "Matthew is out of snacks and beer. \\n\\n He needs to make a quick trip to get some refreshments for him to enjoy that evening.",
      helperText: 'Enter the purpose content text'
    },
    {
      name: 'highlight1',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter text to be highlighted'
        }
      ],
      defaultValue: [
        {text: "snacks and beer"}, 
        {text: "quick trip"}
      ],
      helperText: 'Add phrases that should be highlighted'
    },
    {
      name: 'stepsData1',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the step title'
        },
        {
          name: 'content',
          type: 'longText',
          required: true,
          helperText: 'Enter the step content'
        }
      ],
      defaultValue: [
        {
          title: "Retailer Choice",
          content: "He makes the short drive to BP Gas Station because it's the closest gas station to his house, carries a decent selection, and accepts his college meal plan card."
        },
        {
          title: "In-Store Experience",
          content: "Matthew spends 3-5 minutes browsing for snacks and drinks. He considers a multipack of Cherry High Noon hard seltzers yet ultimately decides against it."
        },
        {
          title: "Purchase",
          content: "In the end, Matthew selects his tried-and-true Cincy Light (6-pack) along with a Sprite. Since turning 21 earlier this year, it's the beer he's used to getting."
        },
        {
          title: "Post Shop",
          content: "The beer and snacks purchased on these BP runs are typically for immediate consumption. Matthew likes to hangout on his porch when he cracks open a beer."
        }
      ],
      helperText: 'Add journey stats with titles and descriptions'
    },
    {
      name: 'shopperJourney1Image1',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/04a6a999738f891b9acc2a9d3ab8428442bbbbe4ab3d3a5f2b7633b013a605ee",
      helperText: 'Upload the first journey visualization image'
    },
    {
      name: 'shopperJourney1Image2',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/1560da4d7c941fe85ddf681a24a7330e7865e7c0c6cc97f38fd1fc93c56e2376",
      helperText: 'Upload the second journey visualization image'
    },
    {
      name: 'profile2CardData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the profile image'
        },
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the profile name'
        },
        {
          name: 'description',
          type: 'string',
          required: true,
          helperText: 'Enter the profile description (age, ethnicity, location)'
        },
        {
          name: 'relationshipStatus',
          type: 'string',
          required: true,
          helperText: 'Enter the relationship status'
        },
        {
          name: 'drinkerType',
          type: 'string',
          enum: [
            'Carefree Fun-Lover',
            'Proud Celebrator',
            'Creative Explorer',
            'Social Curator',
            'Comfort Craver',
            'Loyal Traditionalist',
            'Balanced Enjoyer',
            'Diligent Discoverer'
          ],
          defaultValue: 'Comfort Craver',
          required: true,
          helperText: 'Select the drinker type'
        }
      ],
      defaultValue: {
        image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F4baf9216dadb485893837483cf9af7ac",
        name: "Jay B.",
        description: "53 | White | Sacramento-Yolo, CA",
        relationshipStatus: "Single",
        drinkerType: "Proud Celebrator"
      }
    },
    {
      name: 'purposeContent2',
      type: 'longText',
      required: true,
      defaultValue: "Jay is planning to watch the game tonight. \\n\\n He needs a cold beer to enjoy with his liquor drinks that night.",
      helperText: 'Enter the purpose content text'
    },
    {
      name: 'highlight2',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter text to be highlighted'
        }
      ],
      defaultValue: [
        {text: "planning to watch the game tonight"}, 
        {text: "cold beer to enjoy"}
      ],
      helperText: 'Add phrases that should be highlighted'
    },
    {
      name: 'stepsData2',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the step title'
        },
        {
          name: 'content',
          type: 'longText',
          required: true,
          helperText: 'Enter the step content'
        }
      ],
      defaultValue: [
        {
          title: "Retailer Choice",
          content: "Jay first stopped at Safeway to fulfill his alcohol beverage needs, but was disappointed to learn they don't sell single cans. Jay instead heads to 7-Eleven (a 5-minute drive from Safeway) to get the job done."
        },
        {
          title: "In-Store Experience",
          content: "Once inside 7-Eleven it takes Jay about 30 seconds to locate a Coors Light Tallboy. “This is a good one in the summer,” Jay explains, “it’s a light beer – a good chaser for the shots of whiskey.”"
        },
        {
          title: "Purchase",
          content: "In the end, Jay walks out the door with just one single in hand: a 24-ounce can of Coors Light."
        },
        {
          title: "Post Shop",
          content: "That night Jay will hang at his friend’s home and watch the game. He will enjoy the beer as a chaser with his whiskey."
        }
      ],
      helperText: 'Add journey stats with titles and descriptions'
    },
    {
      name: 'shopperJourney2Image1',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F81ea43c884b54404b4ac911582a4f613",
      helperText: 'Upload the first journey visualization image'
    },
    {
      name: 'shopperJourney2Image2',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0935f7e66ebd491c881dbe6643a072d0",
      helperText: 'Upload the second journey visualization image'
    },
    {
      name: 'profile3CardData',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the profile image'
        },
        {
          name: 'name',
          type: 'string',
          required: true,
          helperText: 'Enter the profile name'
        },
        {
          name: 'description',
          type: 'string',
          required: true,
          helperText: 'Enter the profile description (age, ethnicity, location)'
        },
        {
          name: 'relationshipStatus',
          type: 'string',
          required: true,
          helperText: 'Enter the relationship status'
        },
        {
          name: 'drinkerType',
          type: 'string',
          enum: [
            'Carefree Fun-Lover',
            'Proud Celebrator',
            'Creative Explorer',
            'Social Curator',
            'Comfort Craver',
            'Loyal Traditionalist',
            'Balanced Enjoyer',
            'Diligent Discoverer'
          ],
          defaultValue: 'Comfort Craver',
          required: true,
          helperText: 'Select the drinker type'
        }
      ],
      defaultValue: {
        image: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fdfa27fd4da984d5f8ad100108b88025a",
        name: "Ethan P.",
        description: "21 | Hispanic/White | Dallas, TX",
        relationshipStatus: "Single",
        drinkerType: "Comfort Craver"
      }
    },
    {
      name: 'purposeContent3',
      type: 'longText',
      required: true,
      defaultValue: "Ethan needs to make a quick trip to his closest convenience store for an alcoholic beverage and a Red Bull to enjoy that evening.",
      helperText: 'Enter the purpose content text'
    },
    {
      name: 'highlight3',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter text to be highlighted'
        }
      ],
      defaultValue: [
        {text: "quick trip"}, 
        {text: "for an alcoholic beverage and a Red Bull to enjoy that evening"}
      ],
      helperText: 'Add phrases that should be highlighted'
    },
    {
      name: 'stepsData3',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helperText: 'Enter the step title'
        },
        {
          name: 'content',
          type: 'longText',
          required: true,
          helperText: 'Enter the step content'
        }
      ],
      defaultValue: [
        {
          title: "Retailer Choice",
          content: "7-Eleven is closest to Ethan’s house. As alcohol products are more expensive here, Ethan only goes for a quick trip if the grocery store is closed, or he doesn’t feel up to shopping."
        },
        {
          title: "In-Store Experience",
          content: "After browsing the Red Bulls, Ethan looks at Steel Reserve, Twisted Tea, Smirnoff, Beatbox, & Truly singles for new flavors to try. Singles allow him to taste-test without making a large expenditure."
        },
        {
          title: "Purchase",
          content: "Ultimately, Beatbox is his drink of choice due to the price point, and the C-store happens to carry watermelon, a flavor he likes."
        },
        {
          title: "Post Shop",
          content: "Ethan will enjoy his Beatbox when meeting up with friends before a concert. He will likely also consume a Red Bull, so long as it pairs well with his alcohol of choice."
        }
      ],
      helperText: 'Add journey stats with titles and descriptions'
    },
    {
      name: 'shopperJourney3Image1',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffaeba1d86b18464e8b34ced9cb310202",
      helperText: 'Upload the first journey visualization image'
    },
    {
      name: 'shopperJourney3Image2',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F618e8f20d599479593c63bf0223fe2c0",
      helperText: 'Upload the second journey visualization image'
    }
  ]
});

Builder.registerComponent(TripTypeWrapUpSection, {
  name: 'TripTypeWrapUpSection',
  inputs: [
    {
      name: 'tripTypeColor',
      type: 'string',
      enum: [
        'PITSTOP_BLUE',
        'GRAB_GO_ORANGE',
        'ALC_RELOAD_TEAL',
        'TODAY_MEAL_GREEN',
        'FREQ_FILL_IN_RED',
        'STOCKUP_PURPLE',
      ],
      defaultValue: 'PITSTOP_BLUE',
      required: true,
      helperText: 'Select the color theme for this trip type'
    },
    {
      name: 'tripType',
      type: 'string',
      required: true,
      defaultValue: 'Pit Stop Essentials',
      helperText: 'Enter the trip type name'
    },
    {
      name: 'description',
      type: 'string',
      required: true,
      defaultValue: 'are an in-and-out trip to grab alcohol typically for immediate consumption, and gas, tobacco, or snacks.',
      helperText: 'Enter the trip type description'
    },
    {
      name: 'button1Text',
      type: 'string',
      required: true,
      defaultValue: 'ALCOHOL & MORE',
      helperText: 'Enter the first button text'
    },
    {
      name: 'button2Text',
      type: 'string',
      required: true,
      defaultValue: 'Immediate needs',
      helperText: 'Enter the second button text'
    },
    {
      name: 'stats',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'percentage',
          type: 'number',
          required: true,
          helperText: 'Enter the percentage value'
        },
        {
          name: 'statLabel',
          type: 'string',
          required: true,
          helperText: 'Enter the stat label'
        },
        {
          name: 'value',
          type: 'number',
          required: true,
          helperText: 'Enter the value'
        },
        {
          name: 'tag',
          type: 'number',
          required: true,
          helperText: 'Enter the tag value'
        },
        {
          name: 'tagColor',
          type: 'string',
          required: true,
          enum: [
            'GREEN_STAT_TAG',
            'RED_STAT_TAG',
            'GRAY_STAT_TAG',
          ],
          helperText: 'Select the tag color'
        },
        {
          name: 'metricLabel',
          type: 'string',
          required: true,
          helperText: 'Enter the metric label'
        }
      ],
      defaultValue: [
        {
          percentage: 26,
          statLabel: "of Alc Trips",
          value: 20,
          tag: 49,
          tagColor: "RED_STAT_TAG",
          metricLabel: "Average $$ Spend"
        },
        {
          percentage: 17,
          statLabel: "of Alc Spend",
          value: 77,
          tag: 139,
          tagColor: "GREEN_STAT_TAG",
          metricLabel: "Alc Share of Spend"
        }
      ],
      helperText: 'Add statistics with their details'
    },
    {
      name: 'statFootnote',
      type: 'boolean',
      required: true,
      defaultValue: true,
      helperText: 'Enter the footnote for stats (optional)'
    },
    {
      name: 'themes1',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'theme',
          type: 'text',
          required: true,
          helperText: 'Enter a store choice theme'
        }
      ],
      defaultValue: [
        {theme: 'Store is conveniently located close to home or on my commute'},
        {theme: 'Store stocks the brands I want in the formats I need'},
        {theme: 'Parking, navigation, and check-out enable a quick trip'}
      ],
      helperText: 'Add store choice themes'
    },
    {
      name: 'highlightTexts1',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'text',
          type: 'text',
          required: true,
          helperText: 'Enter text to be highlighted in store themes'
        }
      ],
      defaultValue: [
        {text: 'conveniently located'}, 
        {text: 'brands I want'}, 
        {text: 'formats I need'}, 
        {text: 'enable a quick trip'}
      ],
      helperText: 'Add phrases to highlight in store themes'
    },
    {
      name: 'themes2',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'theme',
          type: 'text',
          required: true,
          helperText: 'Enter an alcohol choice theme'
        }
      ],
      defaultValue: [
        {theme: 'Go-to products that are part of my routine'},
        {theme: 'Products that are cold & ready to drink immediately'},
      ],
      helperText: 'Add alcohol choice themes'
    },
    {
      name: 'highlightTexts2',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'text',
          type: 'text',
          required: true,
          helperText: 'Enter text to be highlighted in alcohol themes'
        }
      ],
      defaultValue: [
        {text: 'Go-to products'}, 
        {text: 'cold & ready to drink'}
      ],
      helperText: 'Add phrases to highlight in alcohol themes'
    }
  ]
});

Builder.registerComponent(ExploreTripTypes, {
  name: 'ExploreTripTypes',
  inputs: [
    {
      name: 'tripTypes',
      type: 'list',
      required: true,
      defaultValue: [
        {
          backgroundColor: 'rgba(255, 150, 33, 1)',
          iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/d4f94b0636cdda70c1e173d495f6a339bfb16ad06d79e2e6c85c1c91179177ed',
          iconWidth: '54px',
          iconAspectRatio: '0.9',
          text: 'Alcohol grab & go',
          navigateTo: '/trip-types/alcohol-grab-go'
        },
        {
          backgroundColor: 'rgba(3, 195, 190, 1)',
          iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/64c03a9256e2107401be79547d1df9b8c89b43ed1a07c956f73c4aec02893ffd',
          iconWidth: '55px',
          iconAspectRatio: '0.92',
          text: 'Alcohol reload',
          navigateTo: '/trip-types/alcohol-reload'
        },
        {
          backgroundColor: 'rgba(6, 111, 192, 1)',
          iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/91843937a63e0cc3af0eb80b545907a04f410f61992069a1ad8e2fbc01ab3f49',
          iconWidth: '38px',
          iconAspectRatio: '0.63',
          text: 'pit stop essentials',
          navigateTo: '/trip-types/pit-stop-essentials'
        },
        {
          backgroundColor: 'rgba(82, 168, 75, 1)',
          iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/c2087ff2624febc4d18ced43ced1d3901891179064ddcdd8e7f1a2ad540abeb7',
          iconWidth: '46px',
          iconAspectRatio: '0.75',
          text: 'today\'s meal+',
          navigateTo: '/trip-types/todays-meal-plus'
        },
        {
          backgroundColor: 'rgba(250, 76, 75, 1)',
          iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/54a4173e665595d247b0c6762b28acad22709e078e641ab3be2fe9beaed93e01',
          iconWidth: '49px',
          iconAspectRatio: '0.82',
          text: 'frequent fill-in',
          navigateTo: '/trip-types/frequent-fill-in'
        },
        {
          backgroundColor: 'rgba(164, 70, 197, 1)',
          iconSrc: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/16a166056dc2e01c5d060a3268e7854c107499e2287d1aededb7cf65820eb8b8',
          iconWidth: '44px',
          iconAspectRatio: '0.73',
          text: 'household stock-up',
          navigateTo: '/trip-types/household-stock-up'
        }
      ],
      subFields: [
        {
          name: 'backgroundColor',
          type: 'color',
          required: true,
          helperText: 'Select the background color for the trip type'
        },
        {
          name: 'iconSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          helperText: 'Upload the icon image'
        },
        {
          name: 'iconWidth',
          type: 'string',
          required: true,
          helperText: 'Enter the icon width (e.g., "54px")'
        },
        {
          name: 'iconAspectRatio',
          type: 'string',
          required: true,
          helperText: 'Enter the icon aspect ratio (e.g., "0.9")'
        },
        {
          name: 'text',
          type: 'string',
          required: true,
          helperText: 'Enter the trip type text'
        },
        {
          name: 'navigateTo',
          type: 'string',
          required: true,
          helperText: 'Enter the navigation path'
        }
      ],
      helperText: 'Configure the trip types to display'
    }
  ]
});

Builder.registerComponent(DownloadSection, {
  name: 'DownloadSection',
  inputs: [
    {
      name: 'title',
      type: 'string',
      required: true,
      defaultValue: 'Pit Stop Essentials Playbook',
      helperText: 'Enter the download item title'
    },
    {
      name: 'description',
      type: 'string',
      required: true,
      defaultValue: 'A guide to Pit Stop Essentials',
      helperText: 'Enter the download item description'
    },
    {
      name: 'fileUrl',
      type: 'file',
      allowedFileTypes: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'],
      required: true,
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6e4cd540426d40ac825387261a164c86',
      helperText: 'Upload the file to be downloaded'
    }
  ]
});
