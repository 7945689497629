import React from 'react';
import { StatCard } from './compound-components/StatCard';
import { ChartCard } from './compound-components/ChartCard';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './TimeToConsumption.module.css';

export function TimeToConsumption({ 
  tripTypeColor,
  timeToConsumptionData, 
  consumptionOccasionsData 
}) {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <section className={styles.section}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionSubtitle}>occasions</h3>
        <h2 className={styles.sectionTitle}>Time to consumption</h2>
      </div>

      <div className={styles.consumptionGroup}>
        <StatCard 
          text={timeToConsumptionData.descriptionText} 
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
        <ChartCard 
          title={timeToConsumptionData.title} 
          image={timeToConsumptionData.image} 
        />
      </div>

      <div className={styles.occasionsGroup}>
        <ChartCard 
          title={consumptionOccasionsData.title} 
          image={consumptionOccasionsData.image } 
          footerNote="Meals occur across Relaxing, Socializing and Daily Dining Occasions" 
        />
        <StatCard 
          text={consumptionOccasionsData.descriptionText} 
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
      </div>
    </section>
  );
}

TimeToConsumption.propTypes = {
  timeToConsumptionData: PropTypes.object.isRequired,
  consumptionOccasionsData: PropTypes.object.isRequired,
  tripTypeColor: PropTypes.string.isRequired
};
