import { COLORS } from '../../../../../constants/CatTripColors'
import PropTypes from 'prop-types';
import styles from './StatCard.module.css';

export function StatCard({ 
  stats,
  tableFooter
}) {
  return (
    <div className={styles.statCard}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/f362c65dd852dd2771a1675f85ff3790e4cd59eccc0bb607414148fce96f6cb5"
        alt="Basket statistics icon"
        className={styles.cardIcon}
      />
      
      <div className={styles.statsContainer}>
        <div className={styles.statGroup}>
          <div className={styles.amtIdxWrap}>
            <div className={styles.amount}>
              ${stats.totalSpend.amount}{tableFooter ? '*' : null}
            </div>
            <div className={styles.index} style={{ backgroundColor: COLORS[stats.totalSpend.tagColor] }}>{stats.totalSpend.index}</div>
          </div>
          <div className={styles.label}>Total Basket Spend</div>
        </div>

        <div className={styles.statGroup}>
          <div className={styles.amtIdxWrap}>
            <div className={styles.amount}>
              {stats.alcShare.amount}%{tableFooter ? '*' : null}
            </div>
            <div className={styles.index} style={{ backgroundColor: COLORS[stats.alcShare.tagColor] }}>{stats.alcShare.index}</div>
          </div>
          <div className={styles.label}>Alc Share of Basket $</div>
        </div>
      </div>
    </div>
  );
}

StatCard.propTypes = {
  stats: PropTypes.object.isRequired,
  tableFooter: PropTypes.bool.isRequired,
};