import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.css';

export const Card = ({ 
  title, 
  children, 
  purposeContent, 
  tripTypeColor,
  accentTextColor
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardInner}>
        <div className={styles.header} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>{title}</div>
        <div className={`${styles.content} ${purposeContent ? styles.purposeContent : ''}`}>{children}</div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  purposeContent: PropTypes.bool,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
