import PropTypes from 'prop-types';
import styles from './Button.module.css';

export const Button = ({ 
  text, 
  variant = 'secondary',
  ...props
}) => {
  return (
    <button 
      className={variant === 'primary' ? styles.primary : styles.secondary}
      tabIndex={0}
      {...props}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};
