import React from 'react';
import StatBlock from './compound-components/StatBlock';
import SegmentTable from './compound-components/SegmentTable';
import PackSizeTable from './compound-components/PackSizeTable';
import PriceTierTable from './compound-components/PriceTierTable';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './BasketCategoryStats.module.css';

export const BasketCategoryStats = ({ 
  tripTypeColor, 
  statCardData, 
  segmentTableData, 
  priceTierTableData, 
  packSizeTableData 
}) => {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.categories}>
      <div className={styles.buttonGroup}>
        <button className={styles.activeButton}>
          Beer Category in Detail
        </button>
        <button className={styles.inactiveButton}>
          Hard Bev Category in Detail
        </button>
      </div>
      <div className={styles.statGroup}>
        <StatBlock statCardData={statCardData} />
        <SegmentTable 
          segmentTableData={segmentTableData} 
          tripTypeColor={COLORS[tripTypeColor]} 
          accentTextColor={accentTextColor}
        />
        <div className={styles.tableContainer}>
          <PackSizeTable 
            packSizeTableData={packSizeTableData} 
            tripTypeColor={COLORS[tripTypeColor]} 
            accentTextColor={accentTextColor}
          />
          <PriceTierTable 
            priceTierTableData={priceTierTableData} 
            tripTypeColor={COLORS[tripTypeColor]} 
            accentTextColor={accentTextColor}
          />
        </div>
      </div>
    </div>
  );
};

BasketCategoryStats.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  statCardData: PropTypes.object.isRequired,
  segmentTableData: PropTypes.array.isRequired,
  priceTierTableData: PropTypes.array.isRequired,
  packSizeTableData: PropTypes.array.isRequired,
};
