import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestimonialCard.module.css';

export const TestimonialCard = ({ 
  quote, 
  author, 
  age, 
  sex, 
  location, 
  imageSrc, 
  iconSrc 
}) => {
  return (
    <button 
      className={styles.testimonialCard}
      onDragStart={(e) => e.preventDefault()}
    >
      <div className={styles.quoteContent}>
        <img 
          src={iconSrc} 
          alt="" 
          className={styles.quoteIcon}
          loading="lazy"
          draggable="false"
        />
        <p className={styles.quoteText}>{quote}</p>
        <div className={styles.authorInfo}>
          <h3 className={styles.authorName}>{author}</h3>
          <div className={styles.authorDetails}>
            <span>{age}</span>
            <div className={styles.divider} />
            <span>{sex}</span>
            <div className={styles.divider} />
            <span>{location}</span>
          </div>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img 
          src={imageSrc} 
          alt={`Testimonial from ${author}`} 
          className={styles.testimonialImage}
          loading="lazy"
          draggable="false"
        />
      </div>
    </button>
  );
};

TestimonialCard.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  sex: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
};
