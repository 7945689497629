import React from 'react';
import { FactorGroup } from './compound-components/FactorGroup';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import PropTypes from 'prop-types';
import styles from './StoreTopFactors.module.css';

export const StoreTopFactors = ({ 
  sectionName,
  factorSectionTitle,
  factor1Title,
  factor2Title,
  tripTypeColor, 
  factor1Data,
  factor2Data
}) => {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <div className={styles.subtitle}>{sectionName}</div>
        <div className={styles.title}>{factorSectionTitle}</div>
      </div>
      <div className={styles.factorsSection}>
        <FactorGroup
          title={factor1Title}
          factors={factor1Data}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
        <FactorGroup
          title={factor2Title}
          factors={factor2Data}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
      </div>
    </div>
  );
};

StoreTopFactors.propTypes = {
  sectionName: PropTypes.string.isRequired,
  factorSectionTitle: PropTypes.string.isRequired,
  factor1Title: PropTypes.string.isRequired,
  factor2Title: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  factor1Data: PropTypes.array.isRequired,
  factor2Data: PropTypes.array.isRequired,
};