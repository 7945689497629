import React from 'react';
import ProgressBar from './ProgressBar';
import { COLORS } from '../../../../../constants/CatTripColors';
import { calculateProgress } from '../../../../../constants/CalculateProgress';
import PropTypes from 'prop-types';
import styles from './SegmentTable.module.css';

const SegmentTable = ({ 
  tripTypeColor, 
  segmentTableData, 
  accentTextColor 
}) => {
  return (
    <div className={styles.tableWrapper}>
      <div 
        className={styles.header} 
        style={{ backgroundColor: tripTypeColor, 
        color: accentTextColor }}
      >
        Beer segments
      </div>
      <div className={`${styles.content} ${styles.segmentContent}`}>
        <div className={styles.label}>% Spend</div>
        {segmentTableData.map((segment) => (
          <div key={segment.name} className={styles.row}>
            <div className={styles.statsProgBarWrap}>
              <div className={styles.catStatWrapper}>
                <div className={styles.name}>{segment.name}</div>
                <div className={styles.stats}>
                  <div className={styles.percentage}>{segment.percentage}%</div>
                  <div className={styles.index} style={{ backgroundColor: COLORS[segment.tagColor] }}>
                    {segment.index}
                  </div>
                </div>
              </div>
              <ProgressBar progress={calculateProgress(segment.percentage)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SegmentTable.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  segmentTableData: PropTypes.array.isRequired,
  accentTextColor: PropTypes.string.isRequired,
};

export default SegmentTable;
