import * as React from "react";
import styles from './NoItems10Basket.module.css';

export const NoItems10Basket = () => {
  return (
    <div className={styles.messageCard}>
      <div className={styles.cardBackground} />
      <div className={styles.messageContent}>
        NO ITEMS WITH &gt;10% BASKET FREQUENCY
      </div>
    </div>
  );
};