import React from 'react';
import PropTypes from 'prop-types';
import styles from './DescriptionBox.module.css';

export const DescriptionBox = ({ children, titleWidth, ...props }) => {
  return (
    <div className={styles.description} {...props}>
      <div className={styles.descriptionTitle} style={{ width: titleWidth }}>
        WHEN ENTERING THE STORE:
      </div>
      <div className={styles.descriptionText}>
        {children}
      </div>
    </div>
  );
}; 

DescriptionBox.propTypes = {
  children: PropTypes.node.isRequired,
  titleWidth: PropTypes.string.isRequired,
};
