import { COLORS } from '../../../../../constants/CatTripColors';
import PropTypes from 'prop-types';
import styles from './ProfileCard.module.css';

export const ProfileCard = ({ 
  image, 
  name, 
  description, 
  relationshipStatus, 
  drinkerType 
}) => {

  function getDrinkerTypeColor(drinkerType) {
    if (drinkerType === 'Carefree Fun-Lover' || drinkerType === 'Proud Celebrator') {
      return COLORS.FAMILIAR_OUTGOING;
    } else if (drinkerType === 'Creative Explorer' || drinkerType === 'Social Curator') {
      return COLORS.OUTGOING_EXPLORATION;
    } else if (drinkerType === 'Comfort Craver' || drinkerType === 'Loyal Traditionalist') {
      return COLORS.RESERVED_FAMILIAR;
    } else if (drinkerType === 'Balanced Enjoyer' || drinkerType === 'Diligent Discoverer') {
      return COLORS.EXPLORATION_RESERVED;
    }
  }
  
  return (
    <div className={styles.profileCard}>
      <div className={styles.imageWrapper}>
        <div className={styles.imageContainer}>
          <img 
            src={image} 
            alt={`Profile of ${name}`}
            className={styles.image}
          />
        </div>
      </div>
      <div className={styles.content}>
        <h2 className={styles.name}>{name}</h2>
        <p className={styles.description}>{description}</p>
        <p className={styles.relationshipStatus}>{relationshipStatus}</p>
        <p className={styles.drinkerType} style={{ color: getDrinkerTypeColor(drinkerType) }}>{drinkerType}</p>
      </div>
    </div>
  );
};

ProfileCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  relationshipStatus: PropTypes.string.isRequired,
  drinkerType: PropTypes.string.isRequired,
};
