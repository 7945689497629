import React from 'react';
import { PatternCard } from './compound-components/PatternCard';
import { StatCard } from './compound-components/StatCard';
import { TableRow } from './compound-components/TableRow';
import { NoItems10Basket } from './compound-components/NoItems10Basket';
import { COLORS } from '../../../../constants/CatTripColors';
import styles from './SpendAnalytics.module.css';
import PropTypes from 'prop-types';


export const SpendAnalytics = ({ 
  whatPeopleBuy, 
  whyPeopleBuy, 
  averageSpend, 
  averageSpendTag,
  averageSpendTagColor,
  alcoholShareOfSpend, 
  alcoholShareOfSpendTag,
  alcoholShareOfSpendTagColor,
  tableData,
  footnote
}) => {
  return (
    <div className={styles.sectionGroup}>
      <div className={styles.paternCardGroup}>
        <PatternCard 
          highlightedWord="What"
          title="people buy?"
          subtitle={whatPeopleBuy}
        />
        <PatternCard 
          highlightedWord="Why"
          title="people buy?"
          subtitle={whyPeopleBuy}
        />
      </div>
      
      <div className={styles.spendBreakdownCard}>
        <div className={`${styles.card} ${styles.breakdownCard}`} />
        <div className={styles.titleGroup}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/3d8d5f6572b5c0a5fc8f3d2a6df5485b34ca04701b45f98809fa80fcad30626c"
            alt="Spend breakdown icon"
            className={styles.icon}
          />
          <div className={styles.title}>SPEND BREAKDOWN</div>
        </div>
        
        <div className={styles.statsContainer}>
          <StatCard 
            value={averageSpend}
            tag={averageSpendTag}
            description="Average $$ Spend"
            statColor={COLORS[averageSpendTagColor]}
            footnote={footnote}
          />
          <StatCard 
            value={alcoholShareOfSpend}
            tag={alcoholShareOfSpendTag}
            description="Alcohol Share of Spend"
            statColor={COLORS[alcoholShareOfSpendTagColor]}
            footnote={footnote}
          />
        </div>
      </div>

      <div className={styles.categoryBreakdown}>
        <div className={`${styles.card} ${styles.categoriesCard}`} />
        <div className={styles.header}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/cf71bf7bf6bc2555a09e0e9ea7a0e78003e32ef64aacd578ebab896b276db93d"
            alt="Non-alcohol categories icon"
            className={styles.icon}
          />
          <div className={styles.title}>Non-Alcohol Categories</div>
        </div>
        
        <div className={styles.tableContainer}>
          {tableData.length ? <>
            <h1 className={styles.tableTitle}>% Frequency in Basket</h1>
            <div className={styles.tableHeader}>
              <div className={styles.columnTitle}>Items</div>
              <div className={styles.columnStats}>
                <div className={styles.percentage}>%</div>
                <div className={styles.index}>Index</div>
              </div>
            </div>
            
            <div className={styles.tableLine} />
            
            {tableData.map((row) => (
              <TableRow
                key={row.item}
                item={row.item}
                percentage={row.percentage}
                index={row.index}
                tagColor={COLORS[row.tagColor]}
              />
            ))}
          </>
          : <NoItems10Basket />
          }
        </div>
      </div>      
      {footnote && 
        <div className={styles.footnote}>*Spend data does not include gas</div>
      }
    </div>
  );
};

SpendAnalytics.propTypes = {
  whatPeopleBuy: PropTypes.string.isRequired,
  whyPeopleBuy: PropTypes.string.isRequired,
  averageSpend: PropTypes.number.isRequired,
  averageSpendTag: PropTypes.number.isRequired,
  averageSpendTagColor: PropTypes.string.isRequired,
  alcoholShareOfSpend: PropTypes.number.isRequired,
  alcoholShareOfSpendTag: PropTypes.number.isRequired,
  alcoholShareOfSpendTagColor: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    tagColor: PropTypes.string.isRequired
  })),
  footnote: PropTypes.bool.isRequired
};
