import React from 'react';
import PropTypes from 'prop-types';
import { KeyPointCard } from './compound-components/KeyPointCard';
import { ColorGradient } from '../../../cat-trip-reuse-components/color-gradient/ColorGradient';
import { SectionHeader } from '../common-components/SectionHeader';
import { COLORS } from '../../../../constants/CatTripColors';
import styles from './WrapUpSection.module.css';


export const WrapUpSection = ({
  wrapUpHeaderSubcat, 
  wrapUpStat1, 
  wrapUpStat2, 
  wrapUpDescription, 
  wrapUpCardsText, 
  wrapUpProductImage,
  subcatAccentColor,
  onAccentTextColor,
  highlightTextColor
}) => {
  return (
    <div className={styles.container}>
      <ColorGradient />
      <SectionHeader
        sectionName={wrapUpHeaderSubcat} 
        sectionTitle="Wrap Up"
        accentColor={COLORS[subcatAccentColor]}
        sectionNameColor={COLORS[highlightTextColor]}
        titleSize="large"
        data-testid="section-header"
      />
      <div className={styles.content}>
        <div className={styles.mainSection}>
          <div className={styles.copySection}>               
            <div className={styles.stats}>
              <div>
                <span style={{ color: COLORS[highlightTextColor] }}>{wrapUpStat1.stat}</span> {wrapUpStat1.label}
              </div>
              <div className={styles.divider} />
              <div>
                <span style={{ color: COLORS[highlightTextColor] }}>{wrapUpStat2.stat}</span> {wrapUpStat2.label}
              </div>
            </div>
            <div className={styles.description}>
              <div className={styles.descriptionText}>
                <span style={{ color: COLORS[highlightTextColor] }}>{wrapUpDescription.highlightSubcat} </span>
                {wrapUpDescription.description}
              </div>
              <div className={styles.cardsContainer}>
                <div className={styles.cardsGrid}>
                  {wrapUpCardsText.map((cardText) => (
                    <KeyPointCard 
                      key={cardText.text} 
                      text={cardText.text}
                      backgroundColor={COLORS[subcatAccentColor]} 
                      textColor={COLORS[onAccentTextColor]} 
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.productsSection}>
          <img 
            src={wrapUpProductImage.imgUrl}
            alt={wrapUpProductImage.altText}
            className={styles.subcatCans}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

WrapUpSection.propTypes = {
  wrapUpHeaderSubcat: PropTypes.string.isRequired,
  wrapUpStat1: PropTypes.shape({
    stat: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  wrapUpStat2: PropTypes.shape({
    stat: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  wrapUpDescription: PropTypes.shape({
    highlightSubcat: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  wrapUpCardsText: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
  })).isRequired,
  wrapUpProductImage: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }).isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
  highlightTextColor: PropTypes.string.isRequired,
};
