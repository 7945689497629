import React from 'react';
import PropTypes from 'prop-types';
import { TestimonialCard } from './compound-components/TestimonialCard';
import { COLORS } from '../../../../constants/CatTripColors';
import { QUOTE_ICONS } from '../../../../constants/ColorQuoteMap';
import styles from './SubcatQuotes.module.css';


export function SubcatQuotes({ 
  quotesData,
  subcatAccentColor,
  onAccentTextColor,
  subTextColor,
  quoteIcon1,
  quoteIcon2,
}) {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>{quotesData.title}</h2>
        <p className={styles.subtitle}>
          {quotesData.subtitle}
        </p>
      </header>
      <div className={styles.testimonialGrid}>
        {quotesData.quotes.map((testimonial) => (
          <TestimonialCard
            key={testimonial.quote.slice(0, 15)}
            {...testimonial}
            subcatAccentColor={COLORS[subcatAccentColor]}
            textColor1={COLORS[onAccentTextColor]}
            textColor2={COLORS[subTextColor]}
            quoteIcon1={QUOTE_ICONS[quoteIcon1]}
            quoteIcon2={QUOTE_ICONS[quoteIcon2]}
          />
        ))}
      </div>
    </section>
  );
}

SubcatQuotes.propTypes = {
  quotesData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    quotes: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })).isRequired,
  }).isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
  subTextColor: PropTypes.string.isRequired,
  quoteIcon1: PropTypes.string.isRequired,
  quoteIcon2: PropTypes.string.isRequired,
};
