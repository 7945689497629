import PropTypes from 'prop-types';
import styles from './DemographicsCard.module.css';

export const DemographicsCard = ({ 
  title, 
  children, 
  tripTypeColor,
  accentTextColor
}) => {
  return (
    <div className={styles.card}>
      <div 
        className={styles.cardHeader} 
        style={{ backgroundColor: tripTypeColor, color: accentTextColor }}
      >
        <div 
          className={styles.cardTitle} 
          style={{ color: accentTextColor }}
        >
          {title}
        </div>
      </div>
      <div className={styles.cardContent}>
        {children}
      </div>
    </div>
  );
};

DemographicsCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
