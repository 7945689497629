import React from 'react';
import PropTypes from 'prop-types';
import { StatCard } from './compound-components/StatCard';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import styles from './TripTypesHeader.module.css';


export const TripTypesHeader = ({
  heroImage,
  title,
  description,
  stat1,
  stat2,
  tripTypeColor,
}) => {

  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <header className={styles.hero}>
      <img
        src={heroImage}
        alt={title}
        className={styles.heroImage}
      />
      <div className={styles.heroContent}>
        <div className={styles.heroTextWrap}>
          <h1 className={styles.heroTitle}>{title}</h1>
          <p className={styles.heroDescription}>{description}</p>
        </div>
        <div className={styles.stats}>
          <StatCard
            value={stat1.stat}
            label={stat1.label}
            color={tripTypeColor}
            textColor={accentTextColor}
          />
          <StatCard
            value={stat2.stat}
            label={stat2.label}
            color={tripTypeColor}
            textColor={accentTextColor}
          />
        </div>
      </div>
    </header>
  );
};


TripTypesHeader.propTypes = {
  heroImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stat1: PropTypes.shape({
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  stat2: PropTypes.shape({
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  tripTypeColor: PropTypes.string.isRequired,
};
