import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatCircle.module.css';

export const StatCircle = ({ 
  percentage, 
  statLabel, 
  tripTypeColor,
  accentTextColor
}) => {
  return (
    <div className={styles.statContainer}>
      <div 
        className={styles.outerCircle} 
        style={{backgroundColor: tripTypeColor}}
      >
        <div 
          className={styles.innerCircle} 
          style={{borderColor: accentTextColor}}
        >
          <div className={styles.content}>
            <div className={styles.percentage} style={{color: accentTextColor}}>{percentage}%</div>
            <div 
              className={styles.label} 
              style={{color: accentTextColor}}
            >
              {statLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StatCircle.propTypes = {
  percentage: PropTypes.number.isRequired,
  statLabel: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
