import React from 'react';
import PropTypes from 'prop-types';
import styles from './IconCard.module.css';

export const IconCard = ({ 
  src, 
  title, 
  alt 
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <img
          loading="lazy"
          src={src}
          alt={alt}
          className={styles.image}
        />
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

IconCard.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  alt: PropTypes.string.isRequired
};