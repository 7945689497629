import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import styles from './DownloadItem.module.css';

export const DownloadItem = ({
  title,
  description,
  fileUrl,
}) => {

  const handleClick = async () => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };
  

  return (
    <div className={styles.downloadItem}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <button className={styles.buttonWrap} onClick={handleClick}>
        <DownloadIcon className={styles.icon} />
      </button>
    </div>
  );
};

DownloadItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
};
